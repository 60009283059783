import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MultiColumn, TwoColumn } from "dg-blocks";

//Assets
import social from "../../assets/images/soc-md-mk-illustr.png";
import why1 from "../../assets/images/why-soc-md-mk-stat-1.png";
import why2 from "../../assets/images/why-soc-md-mk-stat-2.png";
import why3 from "../../assets/images/why-soc-md-mk-stat-3.png";
import why4 from "../../assets/images/why-soc-md-mk-stat-4.png";
import how1 from "../../assets/images/how-soc-md-mk-1.png";
import how2 from "../../assets/images/how-soc-md-mk-2.png";
import how3 from "../../assets/images/how-soc-md-mk-3.png";
import calendar from "../../assets/images/content-calendar.png";
import creation from "../../assets/images/content-creation.png";
import publish from "../../assets/images/content-publish.png";
import campaign from "../../assets/images/soc-media-campaign.png";
import strategy from "../../assets/images/strategy1.png";
import single from "../../assets/images/single-platf.png";
import budget from "../../assets/images/budget.png";
import tracking from "../../assets/images/tracking.png";
import audience from "../../assets/images/audience-persona.png";
import remarketing from "../../assets/images/remarketing.png";
import monitoring from "../../assets/images/monitoring.png";
import optimisation from "../../assets/images/optimisation.png";
import result1 from "../../assets/images/ppc.png";
import result2 from "../../assets/images/web-traffic.png";
import result3 from "../../assets/images/conversion-rate.png";

//Styles
import "./socialMedia.scss";

import Navbar from "../navbar/navbar.component";
import Pricing from "../pricing/pricing.component";
import Footer from "../footer/footer.component";
import GetStarted from "../getStarted/getStarted.component";

let items = [
    <>
        <div className="social-item flex">
            <img src={why1} alt="reason 1" className="img-fluid" />
            <h3>2.32</h3>
            <p>
                billion people are active Facebook users, having signed in at
                least once in the last 30 days
            </p>
        </div>
        <div className="social-item flex">
            <img src={why2} alt="reason 2" className="img-fluid" />
            <h3>3</h3>
            <p>
                hours is the average time per day each individual spends on
                social networks and messaging
            </p>
        </div>
        <div className="social-item flex">
            <img src={why3} alt="reason 3" className="img-fluid" />
            <h3>54%</h3>
            <p>
                of social media users consider or purchase products from their
                feed
            </p>
        </div>
        <div className="social-item flex">
            <img src={why4} alt="reason 4" className="img-fluid" />
            <h3>90%</h3>
            <p>of Instagram users follow at least one brand on the platform</p>
        </div>
    </>
];

let resultItems = [
    <>
        <div className="result-item flex">
            <img src={result1} alt="click through rate" className="img-fluid" />
            <h3>12%</h3>
            <p className="primary">
                average click through rate <br />
                on sponsored posts
            </p>
        </div>
        <div className="result-item flex">
            <img src={result2} alt="website traffic" className="img-fluid" />
            <h3>180</h3>
            <p className="primary">
                increase in <br />
                website traffic
            </p>
        </div>
        <div className="result-item flex">
            <img src={result3} alt="conversion rate" className="img-fluid" />
            <h3>5.3</h3>
            <p className="primary">
                average <br />
                conversion rate
            </p>
        </div>
    </>
];
let whatWeDoItems = [
    <>
        <div className="card-row flex">
            <div className="card text-center">
                <div className="img">
                    <img
                        src={campaign}
                        alt="campaign development"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Campaign development</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={creation}
                        alt="content creation"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">
                    Creative content creation for organic and paid content
                </p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={calendar}
                        alt="content calendar"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Organic content calendar</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={publish}
                        alt="content dissemination"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Content dissemination</p>
            </div>
        </div>
        <div className="card-row flex">
            <div className="card text-center">
                <div className="img">
                    <img src={strategy} alt="" className="img-fluid" />
                </div>
                <p className="primary">Multi-platform social media strategy</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={single}
                        alt="social media strategy"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Single platform social media strategy</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={budget}
                        alt="budget optimization"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Budget optimization</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={tracking}
                        alt="Conversion tracking"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Conversion tracking</p>
            </div>
        </div>
        <div className="card-row flex">
            <div className="card text-center">
                <div className="img">
                    <img
                        src={audience}
                        alt="SEO audits"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Audience persona's and development</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={remarketing}
                        alt="SEO audits"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Remarketing strategy</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={monitoring}
                        alt="SEO audits"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Campaign monitoring</p>
            </div>
            <div className="card text-center">
                <div className="img">
                    <img
                        src={optimisation}
                        alt="SEO audits"
                        className="img-fluid"
                    />
                </div>
                <p className="primary">Campaign optimisation</p>
            </div>
        </div>
    </>
];
class SocialMedia extends Component {
    render() {
        return (
            <div className="container-fluid SocialMedia">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>SOCIAL MEDIA MARKETING</h1>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center">
                        <h2 className="text-green">
                            What is Social Media Marketing?
                        </h2>
                    </div>
                    <div className="col-sm-4 text-md-left text-center">
                        <div className="block-container">
                            <p>
                                Social media marketing encompasses a number of
                                platforms such as Facebook, Instagram, Linkedin,
                                Twitter and more where you can target your
                                audience with sponsored posts to drive them
                                towards an action on your website, whether
                                that’s to purchase a product, view an event or
                                even contact you directly.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-3 text-center">
                        <img
                            src={social}
                            alt="Social Media Marketing"
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-sm-4 text-md-left text-center">
                        <div className="block-container">
                            <p>
                                Social media marketing is an effective platform
                                for almost every business in both the B2B and
                                B2C sector. With 3.5 billion people worldwide
                                using social media per day, chances are your
                                audience is there too.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 text-center">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <MultiColumn
                    containerClass={"why-social-media"}
                    headerClass={"header-container flex"}
                    header={
                        <h2 className="text-green">
                            What is Social Media Marketing?
                        </h2>
                    }
                    posts={items}
                    rowClass={"container-row"}
                    columnClass={"flex"}
                    footerClass={"footer-container flex"}
                    footer={
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <div className="how-we-help">
                    <div className="header-container flex">
                        <h2 className="text-green">How Digigrow can help</h2>
                    </div>
                    <TwoColumn
                        containerClass="container-row flex"
                        rightColClass="flex col"
                        rightColumn={
                            <img
                                src={how1}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <p className="primary">
                                Digigrow are social media pros and experts at
                                multi-platform marketing, developing a cohesive
                                strategy to define your audience, target them,
                                and remarket to them ensuring that your product
                                or service is viewed by the right eyes.
                            </p>
                        }
                    />
                    <TwoColumn
                        containerClass="container-row reverse flex"
                        leftColClass="flex col"
                        leftColumn={
                            <>
                                <img
                                    src={how3}
                                    className="img-fluid"
                                    alt="how we help"
                                />
                                <img
                                    src={how2}
                                    className="absolute"
                                    alt="gears"
                                />
                            </>
                        }
                        rightColClass="flex col"
                        rightColumn={
                            <p className="primary">
                                Digigrow are social media pros and experts at
                                multi-platform marketing, developing a cohesive
                                strategy to define your audience, target them,
                                and remarket to them ensuring that your product
                                or service is viewed by the right eyes.
                            </p>
                        }
                    />
                    <div className="footer-container flex">
                        <p className="primary">
                            Backed by our Pay as you Grow guarantee, whether
                            it’s Facebook, Instagram, LinkedIn or Twitter we can
                            ensure that you have a cohesive and defined strategy
                            guaranteed to grow your business and engage with
                            your audience.
                        </p>
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <MultiColumn
                    containerClass={"what-we-do"}
                    headerClass={"header-container flex"}
                    header={<h2 className="text-green">What we do</h2>}
                    posts={whatWeDoItems}
                    rowClass={"container-row"}
                    columnClass={"flex"}
                    footerClass={"footer-container flex"}
                    footer={
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <MultiColumn
                    containerClass={"our-results flex"}
                    headerClass={"header-container flex"}
                    header={<h2 className="text-white">Our results</h2>}
                    posts={resultItems}
                    rowClass={"container-row flex"}
                    columnClass={"flex"}
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <Pricing />
                <GetStarted />
                <Footer />
            </div>
        );
    }
}

export default connect(null, {})(SocialMedia);
