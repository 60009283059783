import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../../actions/login.action';
import history from '../../history';
import '../signup/signup.scss';

import Navbar from '../navbar/navbar.component';
import Footer from '../footer/footer.component';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        let projectName = `${process.env.REACT_APP_PROJECT_NAME}`;
        const userCredentials = {
            email: this.state.email,
            password: this.state.password
        };

        this.props.login(userCredentials)
        .then(res => {
            localStorage.setItem(`${projectName}::access_token`, res.access_token);
            history.push('/admin/posts');
        })
        .catch(res => {
            alert(res.message);
        });
    }

    render() {
        return (

            <div className="container-fluid SIGNUP">
                <div className="row background-img-full">
                    <div className="col-12 text-center">
                        <Navbar />
                        
                        <div className="container">
                            <div className="row mb-4">
                                <div className="offset-sm-1 col-sm-10 col-12 text-center align-self-center">
                                    <div className="card">
                                        <div className="card-body">
                                            <h1><span>Please LogIn</span></h1>
                                            <div className={this.state.submitted ? "alert alert-success" : 'd-none'} role="alert">
                                                Login successful!
                                            </div>
                                            <form onSubmit={this.onSubmit}>
                                                <div className="form-group row">
                                                    <input type="email" className="form-control" name="email" placeholder="Enter email" required value={this.state.email} onChange={this.onChange} />
                                                </div>
                                                <div className="form-group row">
                                                    <input type="password" className="form-control" name="password" placeholder="Password" required value={this.state.password} onChange={this.onChange} />
                                                </div>
                                                
                                                <button type="submit" className="btn btn-primary">Login</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div> 
        );
    }
}

Login.propTypes = {
    login: PropTypes.func.isRequired
};

export default connect(null, { login })(Login);
