import React, { Component } from 'react';
import PostList from '../blog/postList.component';
import Sidebar from '../sidebar/sidebar.admin.component';

class PostListPage extends Component {
    
    render() {
        return (
            <div >
                <Sidebar />
                <main>
                    <PostList />
                </main>
                
            </div>
        );
    }
}

export default PostListPage;
