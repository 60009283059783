import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BlogFormNew } from '../blog/form.blog.component';
import Sidebar from '../sidebar/sidebar.admin.component';
import { createNewPost, setClearPostForm } from '../../../actions/post.action';

class NewPostPage extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        // Clear Form === false
        this.props.setClearPostForm(false);
    }

    onSubmit(form) {
        this.props.createNewPost(form);
    }
    
    render() {
        return (
            <div >
                <Sidebar />
                <main>
                    <h1>New Post</h1>
                    <BlogFormNew onSubmit={this.onSubmit} />
                </main>
                
            </div>
        );
    }
}

export default connect(null, {createNewPost, setClearPostForm})(NewPostPage);
