export const FETCH_POSTS = 'FETCH_POSTS';
export const NEW_POST = 'NEW_POST';
export const SELECT_POST = 'SELECT_POST';
export const CLEAR_POST_FORM = 'CLEAR_POST_FORM';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const NEW_CLIENT = 'NEW_CLIENT';
export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const NEW_LEAD_PHONE = 'NEW_LEAD_PHONE';
export const GET_LEADS = 'GET_LEADS';
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';
export const GET_COUNTRY = 'GET_COUNTRY';