import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import paid_search_large from '../../assets/images/paid_search_large.svg';
import social_media_large from '../../assets/images/social_media_large.svg';
import content_creation_large from '../../assets/images/content_creation_large.svg';
import seo_large from '../../assets/images/seo_large.svg';
import website_creation from '../../assets/images/website_creation.svg';

import './services.scss';

import Navbar from '../navbar/navbar.component';
import Footer from '../footer/footer.component';

class Services extends Component {

    render() {
        return (
            <div className="container-fluid ABOUT_US">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>Pay As You Grow Services</h1>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 text-center">
                        <img src={seo_large} alt="Search Engine Optimisation (SEO)" className="img-fluid" />
                    </div>
                    <div className="col-sm-6 center-text">
                        <h2 className="text-green">Search Engine Optimisation (SEO)</h2>
                        <p>
                            Search engine optimization is all about getting you to the front page of google. When executed correctly you will see an increase in traffic to your website and engagement with your users. We work with you to analyse your competitors and your business to help increase your rankings, and ultimately your ROI.                        
                        </p>
                        <Link to="/seo" className="btn btn-primary">Learn More</Link>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 center-text">
                        <h2 className="text-green">Paid search</h2>
                        <p>
                            Paid Search helps to give you an edge over your competitors and stand out online. With thoroughly constructed, data driven campaigns we work to increase conversions through to your website. Each campaign is unique and tailored to grow your business. Even better? It’s backed by our Pay as you Grow guarantee.                        
                        </p>
                        <Link to="/paid-search" className="btn btn-primary">Learn More</Link>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={paid_search_large} alt="Paid search" className="img-fluid" />
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 text-center">
                        <img src={social_media_large} alt="Social media marketing" className="img-fluid" />
                    </div>
                    <div className="col-sm-6 center-text">
                        <h2 className="text-green">Social media marketing</h2>
                        <p>
                            Social media marketing has become essential to the growth of any business. Whether it’s paid advertising, organic content creation, consulting and more we work with you to create data driven KPI’s and nurture your online social presence and get you in front of your audience loud and clear.                         
                        </p>
                        <Link to="/social-media-marketing" className="btn btn-primary">Learn More</Link>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 center-text">
                        <h2 className="text-green">Content Creation</h2>
                        <p>
                            Content creation is the key to any digital marketing strategy. From engaging graphics, captivating video and SEO optimized web content and blogs to drive traffic to your website and capture your audience, we have you covered. Completely unique and completely on brand.                        
                        </p>
                        <Link to="/content-creation" className="btn btn-primary">Learn More</Link>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={content_creation_large} alt="Contant Creation" className="img-fluid" />
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 text-center">
                        <img src={website_creation} alt="Website Creation" className="img-fluid" />
                    </div>
                    <div className="col-sm-6 center-text">
                        <h2 className="text-green">Website Creation</h2>
                        <p>
                            Your website is the calling card of your business. A good website can help you to increase your online presence and grow your business. We create completely customized agile, engaging websites that are guaranteed to represent your business. Whether you’re looking for a standard landing page, a full e-commerce shop or something in between, we’ll have it covered on-time, on-budget & on-brand.                         
                        </p>
                        <Link to="/website-development" className="btn btn-primary">Learn More</Link>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(null, { })(Services);
