import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import WYSIWUG_Editor from './WYSIWUG_Editor';
import './form.blog.scss';
import { connect } from 'react-redux';
import { selectPost } from '../../../actions/post.action';

const afterSubmit = (result, dispatch) => dispatch(reset('BlogForm_New'));

let BlogForm_New = props => {
    const { handleSubmit, pristine, submitting } = props;
    return (
      <form onSubmit={handleSubmit} className="BlogForm">
        <div className="form-group title">
            <label>Post Title</label>
            <Field name="title" component="input" type="text" placeholder="My New Post" />
            <Field name="status" component="select">
                <option value="Draft">Draft</option>
                <option value="Published">Published</option>
            </Field>
            <button type="submit" className="btn-primary" disabled={pristine || submitting}>Save</button>
        </div>
        <div>
            <Field name="wysiwyg" component={WYSIWUG_Editor} />
        </div>
      </form>
    );
  };
  
BlogForm_New = reduxForm({
    form: 'BlogForm_New', // a unique identifier for this form
    onSubmitSuccess: afterSubmit,
})(BlogForm_New);

BlogForm_New = connect(null,{ })(BlogForm_New)

let BlogForm_Edit = props => {
    const { handleSubmit, pristine, submitting } = props;
    return (
      <form onSubmit={handleSubmit} className="BlogForm">
        <div className="form-group title">
            <label>Post Title</label>
            <Field name="title" component="input" type="text" placeholder="My New Post" />
            <Field name="status" component="select">
                <option value="Draft">Draft</option>
                <option value="Published">Published</option>
            </Field>
            <button type="submit" className="btn-primary" disabled={pristine || submitting}>Save</button>
        </div>
        <div>
            <Field name="wysiwyg" component={WYSIWUG_Editor} />
        </div>
      </form>
    );
  };
  
BlogForm_Edit = reduxForm({
    form: 'BlogForm_Edit', // a unique identifier for this form
})(BlogForm_Edit);

BlogForm_Edit = connect(
    state => ({
    initialValues: state.posts.currentPost // pull initial values from account reducer
    }),
    { selectPost } // bind account loading action creator
)(BlogForm_Edit)

export const BlogFormEdit = BlogForm_Edit;
export const BlogFormNew = BlogForm_New;
