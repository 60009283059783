import React, { Component } from "react";
import { Link } from "react-router-dom";

//Assets
import step1 from "../../assets/images/cta-illustr.png";

//Styles
import "./getStarted.scss";

class GetStarted extends Component {
    render() {
        return (
                <div className="get-started-container">
                    <div className="col-12 get-started text-center">
                        <img src={step1} alt="Apply to 1:1 workshop with us" />
                        <div className="text-container">
                            <h2 className="text-white">Get Started With Us</h2>
                            <Link
                                to="/contact"
                                className="btn btn-primary-inverted"
                            >
                                Apply Now
                            </Link>
                        </div>
                    </div>
                </div>
        );
    }
}

export default GetStarted;
