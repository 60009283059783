import { GET_COUNTRY } from './types';

export const getCountry = () => dispatch => {
    fetch('https://extreme-ip-lookup.com/json/')
    .then(res => res.json())
    .then(posts => dispatch({
        type: GET_COUNTRY,
        payload: posts
    }));
};
