import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLeads } from '../../../actions/lead.action';
import './leads.scss';
import Sidebar from '../sidebar/sidebar.admin.component';

class Leads extends Component {

    componentWillMount() {
        this.props.getLeads();
    }

    render() {
        const leads = this.props.leads.map((lead, index) => (
            <tr key={`lead_#${index}`}>
                <th scope="row">{index + 1}</th>
                <td>{lead.name}</td>
                <td>{lead.email}</td>
                <td>{lead.phone}</td>
            </tr>
        ));

        return (
            <div className="PostList Admin">
                <Sidebar />
                <main>
                    <h1>Leads</h1>

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leads}
                            </tbody>
                        </table>
                    </div>
                </main>

            </div>
        )
    }
}

Leads.propTypes = {
    getLeads: PropTypes.func.isRequired,
    leads: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    leads: state.leads.leads,
});

export default connect(mapStateToProps, { getLeads })(Leads);
