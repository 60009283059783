import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Chat from "../../assets/images/chat.svg";
import Telephone from "../../assets/images/telephone.svg";
import Email from "../../assets/images/email.svg";
import "./contact.scss";
import { createLead } from "../../actions/lead.action";

class ContactButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phone: "",
            email: "",
            message: "",
            submitted: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        const post = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            message: this.state.message
        };

        this.props
            .createLead(post)
            .then(res => {
                this.setState({
                    name: "",
                    phone: "",
                    email: "",
                    message: "",
                    submitted: true
                });
                setTimeout(() => {
                    this.setState({ submitted: false });
                }, 3000);
            })
            .catch(res => {
                alert("success");
            });
    }

    render() {
        return (
            <div className="container CONTACT">
                <div className="row ">
                    <div className="col-sm-4 col-12 text-center">
                        <div className="card">
                            <div className="card-body">
                                <img
                                    src={Chat}
                                    alt="Chat with Us"
                                    className="img-fluid"
                                />
                                <p>Chat with Us</p>
                            </div>
                            <div className="contact-form">
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Our Contact Details are:</h2>
                                    </div>
                                    <div className="col-12">
                                        <a href="mailto:info@digigrow.ie">
                                            info@digigrow.ie
                                        </a>
                                    </div>
                                    <div className="col-12 usa">
                                        <a href="tel:+18573475735">
                                            USA: +1 857 347 5735
                                        </a>
                                    </div>
                                    <div className="col-12">
                                        <a href="tel:+35312118550">
                                            Ireland: +353 1 211 8550
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-12 text-center">
                        <div className="card">
                            <div className="card-body ">
                                <img
                                    src={Telephone}
                                    alt="Have us call you"
                                    className="img-fluid"
                                />
                                <p>Have us call you</p>
                            </div>
                            <div className="contact-form">
                                <div
                                    className={
                                        this.state.submitted
                                            ? "alert alert-success"
                                            : "d-none"
                                    }
                                    role="alert"
                                >
                                    Thank you, we will be in touch shortly!
                                </div>
                                <form
                                    onSubmit={this.onSubmit}
                                    className="phone"
                                >
                                    <div className="form-group row">
                                        <div className="col-12 text-center">
                                            <label htmlFor="phone">
                                                What's your phone number?{" "}
                                            </label>
                                        </div>
                                        <div className="col-12 text-center">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="phone"
                                                value={this.state.phone}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-12 text-center">
                        <div className="card">
                            <div className="card-body">
                                <img
                                    src={Email}
                                    alt="Email Us"
                                    className="img-fluid"
                                />
                                <p>Email Us</p>
                            </div>
                            <div className="contact-form ">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                >
                                    Get in Touch
                                </button>
                                <div
                                    className={
                                        this.state.submitted
                                            ? "alert alert-success"
                                            : "d-none"
                                    }
                                    role="alert"
                                >
                                    Thank you for getting in touch, our team
                                    will contact you shortly!
                                </div>
                                <form
                                    onSubmit={this.onSubmit}
                                    className="phone d-none"
                                >
                                    <div className="form-group row">
                                        <div className="col-12 text-center">
                                            <label htmlFor="email">
                                                What's your email?{" "}
                                            </label>
                                        </div>
                                        <div className="col-12 text-center">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Modal --> */}
                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 text-right">
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                <span aria-hidden="true">
                                                    &times;
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div
                                                className={
                                                    this.state.submitted
                                                        ? "alert alert-success"
                                                        : "d-none"
                                                }
                                                role="alert"
                                            >
                                                Thank you for getting in touch,
                                                our team will contact you
                                                shortly!
                                            </div>
                                            <form
                                                onSubmit={this.onSubmit}
                                                className="phone"
                                            >
                                                <div className="form-group row">
                                                    <div className="col-12 text-center">
                                                        <label htmlFor="email">
                                                            What's your email?{" "}
                                                        </label>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="email"
                                                            value={
                                                                this.state.email
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12 text-center">
                                                        <label htmlFor="message">
                                                            Your message?{" "}
                                                        </label>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <textarea
                                                            rows="4"
                                                            className="form-control"
                                                            name="message"
                                                            value={
                                                                this.state
                                                                    .message
                                                            }
                                                            onChange={
                                                                this.onChange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary"
                                                        >
                                                            Send
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ContactButtons.propTypes = {
    createLead: PropTypes.func.isRequired
};

export default connect(null, { createLead })(ContactButtons);
