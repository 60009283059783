import React, { Component } from "react";
import { connect } from "react-redux";

import "./results.scss";

//Assets
import error from "../../assets/images/error-reduction.png";
import website from "../../assets/images/website-ranking.png";
import keyword from "../../assets/images/keyword-ranking.png";

class Results extends Component {
    render() {
        return (
            <div className="results">
                <div className="text-center">
                    <h2 className="text-green">Our results</h2>
                </div>
                <div className="charts">
                    <div className="column-container">
                        <div className="img">
                            <p className="percentage">44%</p>
                            <img
                                src={website}
                                alt="Increase in website ranking"
                                className="img-fluid"
                            />
                        </div>
                        <p>Increase in website rankings</p>
                    </div>
                    <div className="column-container">
                        <div className="img">
                            <p className="percentage">68%</p>
                            <img
                                src={keyword}
                                alt="Increase in keyword ranking"
                                className="img-fluid"
                            />
                        </div>
                        <p>Increase in website rankings</p>
                    </div>
                    <div className="column-container">
                        <div className="img">
                            <p className="percentage">82%</p>
                            <img
                                src={error}
                                alt="Increase in error"
                                className="img-fluid"
                            />
                        </div>
                        <p className="margin-top">Reduction in technical error</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {})(Results);
