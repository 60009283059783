import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import loginReducer from './login.reducer';
import postReducer from './post.reducer';
import clientReducer from './client.reducer';
import pricingReduxer from './pricing.reducer';
import { reducer as formReducer } from 'redux-form'
import leadReducer from './lead.reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    loggedInUser: loginReducer,
    posts: postReducer,
    clients: clientReducer,
    pricing: pricingReduxer,
    form: formReducer,
    leads: leadReducer
});

export default createRootReducer;