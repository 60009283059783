import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./whySeo.scss";

class WhySeo extends Component {
    render() {
        return (
            <div className="row why-seo">
                <div className="col-12 why-container">
                    <div className="text-md-left text-center">
                        <h2 className="text-green">Why do you need SEO?</h2>
                    </div>
                    <div className="col-12 row">
                        <div className="col-6 stat">
                            <h3>67'000</h3>
                            <p>
                                is the number of searches performed on Google
                                every second
                            </p>
                        </div>
                        <div className="col-6 stat">
                            <h3>93%</h3>
                            <p>
                                of new online experiences begin with a search
                                engine
                            </p>
                        </div>
                    </div>
                    <div className="col-12 row">
                        <div className="col-6 stat">
                            <h3>68%</h3>
                            <p>
                                of all search engine clicks are in the first 5
                                result on Google
                            </p>
                        </div>
                        <div className="col-6 stat">
                            <h3>600+</h3>
                            <p>
                                the amount of Google algorithm changes in any
                                given year
                            </p>
                        </div>
                    </div>
                    <div className="text-md-left text-center">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {})(WhySeo);
