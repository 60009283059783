import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../../history';
import './ourWork.scss';

import { fetchClients, selectClient, setCurrentClient } from '../../actions/client.action';

class OurWork extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: []
        }
    }

    componentDidMount() {
        this.props.fetchClients()
        .then(data => {
            this.setState({ clients: this.props.clients });
        });
    }

    isMobileDevice() {
        return window.window.innerWidth < 786;
    };

    onMouseOver(index) {
        if(!this.isMobileDevice()) {
            let tmp = this.state.clients;

            if(index%3 === 2) {
                tmp[index-1].hide = true;
            } else {
                tmp[index+1].hide = true;
            }

            tmp[index].selected = true;
            this.setState({ clients: tmp });
        }
    }

    onMouseOut(index) {
        if(!this.isMobileDevice()) {
            let tmp = this.state.clients;
            
            if(index%3 === 2) {
                tmp[index-1].hide = false;
            } else {
                tmp[index+1].hide = false;
            }
            
            tmp[index].selected = false;
            this.setState({ clients: tmp });
        }
    }

    onClick(index) {
        if(this.isMobileDevice()) {
            let tmp = this.state.clients;
            
            tmp[index].selected = !tmp[index].selected;
            this.setState({ clients: tmp });
        }
    }

    viewCaseStudy(client) {
        this.props.setCurrentClient(client)
        .then(res => {
            window.scrollTo(0,0);
            history.push('/case-study');
        });
    }

    showStats(client) {
        let stats;
        
        if(client.case_study && client.case_study.the_results && client.case_study.the_results.length > 0) {
            stats = client.case_study.the_results.slice(0, 3).map((result, index) => (
                <div key={`ourWork_stats_${index}`} className="col-sm-4 text-center">
                    <p className="text-green">{result.key}</p>
                    <p>{result.value}</p>
                </div>
            ))
        } else {
            stats = (
                <div></div>
            )
        }

        return stats
    }

    render() {
        const workList = this.state.clients.map((client, index) => (
                <div 
                    key={client._id}   
                    className={!client.selected ? (client.hide ? `d-none` : `col-sm-4 text-center tile`) : `col-sm-8 text-center tile`} 
                    style={{backgroundImage: `url(${client.backgroundImage})`}}
                    onMouseOver={() => this.onMouseOver(index)}
                    onMouseOut={() => this.onMouseOut(index)}
                    onClick={() => this.onClick(index)}
                >
                    <img src={client.logo} alt={client.company_name} className="img-fluid" />
                    
                    <div className="container stats">
                        <div className="row">
                            <div className="col-12 text-center">
                                <img src={client.logo} alt={client.company_name} className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            {this.showStats(client)}
                        </div>
                        <div className={client.case_study ? "row":'d-none'}>
                            <div className="col-12">
                                <button type="button" className="btn btn-primary" onClick={() => {this.viewCaseStudy(client)}}>See The Work</button>
                            </div>
                        </div>
                    </div>
                </div>
        ));

        return (
            <div className="row OUR_WORK">
                <div className="col-12 work_title">
                    <div className="heading">
                        <h2>Our Work</h2>
                    </div>
                </div>
                {workList}
            </div>
        );
    }
}

OurWork.propTypes = {
    fetchClients: PropTypes.func.isRequired,
    selectClient: PropTypes.func.isRequired,
    setCurrentClient: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    newClient: PropTypes.object
}

const mapStateToProps = state => ({
    clients: state.clients.clients,
    newClient: state.clients.client
});

export default connect(mapStateToProps, { fetchClients, selectClient, setCurrentClient })(OurWork);