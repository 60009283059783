import { GET_COUNTRY } from '../actions/types';

const initialState = {
    clientInfo: {}
};

export default function (state = initialState, action) {
    switch(action.type) {
        case GET_COUNTRY: 
            return {
                ...state,
                clientInfo: action.payload
            }
        default:
            return state;
    }
}
