import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './post.blog.scss';

import PostList from '../list.posts.component';
import Navbar from '../../navbar/navbar.component';
import Footer from '../../footer/footer.component';

class BlogPost extends Component {

    render() {

        return (
            <div className="container-fluid BLOG_POST">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-4">
                            <h1 className="text-green">{this.props.post.title}</h1>
                        </div>
                        <div className="col-12 mt-4 mb-4" dangerouslySetInnerHTML={{__html: this.props.post.wysiwyg}}></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <p className="text-green">Share on:</p>
                        </div>
                        <div className="col-sm-6 text-right">
                            <p className="text-muted">Posted on: {new Date(this.props.post.createdAt).toLocaleDateString()}</p>
                        </div>
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h3>Related Articles</h3>
                        </div> 
                    </div>
                    <PostList numPosts={3} />
                </div>
                
                <Footer />
            </div>
        );
    }
}

BlogPost.propTypes = {
    post: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    post: state.posts.currentPost
});

export default connect(mapStateToProps, { })(BlogPost);