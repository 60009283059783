import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPosts, selectPost } from '../../../actions/post.action';
import './postList.scss';
import history from '../../../history';

class PostList extends Component {

    componentDidMount() {
        this.props.fetchPosts();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.newPost) {
            this.props.posts.unshift(nextProps.newPost);
        }
    }

    selectPost(post) {
        this.props.selectPost(post);
        history.push('/admin/post');
    }

    render() {
        const postItems = this.props.posts.map(post => (
            <div className="card mb-4 shadow-sm" key={post._id}>
                <div className="card-body">
                        <button className="btn-link" onClick={() => this.selectPost(post)} >
                            {post.title}
                        </button>
                    <h4 className="text-muted">
                        By {post.created_by.displayName} - {new Date(post.createdAt).toLocaleDateString('en-uk', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </h4>
                    <div className="card-text" dangerouslySetInnerHTML={{__html: post.wysiwyg}}></div>
                </div>
            </div>
        ));

        return (
            <div className="PostList Admin">
                <h1>Posts</h1>

                {postItems}
            </div>
        )
    }
}

PostList.propTypes = {
    fetchPosts: PropTypes.func.isRequired,
    selectPost: PropTypes.func.isRequired,
    posts: PropTypes.array.isRequired,
    newPost: PropTypes.object
}

const mapStateToProps = state => ({
    posts: state.posts.items,
    newPost: state.posts.item
});

export default connect(mapStateToProps, { fetchPosts, selectPost })(PostList);
