import React, { Component } from 'react';
import { connect } from 'react-redux';
import './contact.scss';

import contact_us_icon from '../../assets/images/contact_us_icon.svg';

import Navbar from '../navbar/navbar.component';
import ContactButtons from './contactButtons.component';
import Footer from '../footer/footer.component';

class ContactPage extends Component {

    render() {
        return (
            <div className="container-fluid CONTACT flex-column">
                <div className="row background-img-full">
                    <div className="col-12 align-self-top">
                        <Navbar />
                    </div>
                    <div className="col-12 text-center align-self-center">
                        <h1>Contact Us</h1>
                        <ContactButtons />
                    </div>
                    <div className="col-12 text-center mb-4 align-self-end">
                        <img src={contact_us_icon} alt="Contact Us" className="img-fluid" />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(null, { })(ContactPage);