import { FETCH_POSTS, NEW_POST, SELECT_POST, CLEAR_POST_FORM } from './types';

export const fetchPosts = () => dispatch => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog`)
    .then(res => res.json())
        .then(posts => {
           console.log(posts)
         return dispatch({
        type: FETCH_POSTS,
        payload: posts
    })});
};

export const selectPost = (postData) => (dispatch) => {
    dispatch({
        type: SELECT_POST,
        payload: postData
    });
}

export const createNewPost = (postData) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        console.log(getState());
        postData['created_by'] = getState().loggedInUser.user;
        console.log(postData);
        resolve(postData);
        
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/create`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        .then(res => res.json())
        .then(post => dispatch({
            type: NEW_POST,
            payload: post
        }));
    });
};

export const updatePost = (postData) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        console.log(getState());
        postData['created_by'] = getState().loggedInUser.user;
        console.log(postData);
        resolve(postData);
        
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/update/${postData._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        .then(res => res.json())
        .then(post => dispatch({
            type: NEW_POST,
            payload: post
        }));
    });
};

export const setClearPostForm = (postData) => (dispatch) => {
    dispatch({
        type: CLEAR_POST_FORM,
        payload: postData
    });
}
