import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./pricing.scss";
import { getCountry } from "../../actions/pricing.action";

class Pricing extends Component {
    componentWillMount() {
        this.props.getCountry();
    }

    getCurrencySymbol(countryCode) {
        let currencySymbol = "€";
        if (countryCode === "GB") {
            currencySymbol = "£";
        } else if (countryCode === "US" || countryCode === "CA") {
            currencySymbol = "$";
        }

        return currencySymbol;
    }

    render() {
        return (
            <div className="row OUR_PRICING">
                <div className="col-12 text-center mb-4">
                    <h2 className="text-green">Our Pricing</h2>
                    <h3 className="text-green">
                        All packages are uniquely tailored to your business and
                        backed by our Pay as you Grow guarantee.{" "}
                    </h3>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="card">
                                <div className="card-header text-center">
                                    <h3 className="pt-4">12</h3>
                                    <h4>
                                        <small>hours per month</small>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <p className="mb-4">
                                                Suitable for small businesses
                                                who may not require significant
                                                engagement but would like
                                                ongoing consultation and input
                                                focused on specific services.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6 text-right">
                                            <p className="text-green">
                                                {this.props.clientInfo
                                                    .countryCode === "IE"
                                                    ? 750
                                                    : this.props.clientInfo
                                                          .countryCode === "US"
                                                    ? 900
                                                    : this.props.clientInfo
                                                          .countryCode === "GB"
                                                    ? 700
                                                    : this.props.clientInfo
                                                          .countryCode === "CA"
                                                    ? 1150
                                                    : "750"}
                                            </p>
                                        </div>
                                        <div className="col-6 text-left">
                                            <div className="col-12">
                                                <p className="text-green">
                                                    {this.getCurrencySymbol(
                                                        this.props.clientInfo
                                                            .countryCode
                                                    )}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-green">
                                                    per month
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <Link
                                                to="/contact"
                                                className="btn btn-primary"
                                            >
                                                Order Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-header text-center">
                                    <h3 className="pt-4">17</h3>
                                    <h4>
                                        <small>hours per month</small>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <p className="mb-4">
                                                Suitable for businesses who wish
                                                to grow and would like more
                                                hands on engagement from
                                                Digigrow which will be focused
                                                on a wider range of services
                                                simultaneously.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6 text-right">
                                            <p className="text-green">
                                                {this.props.clientInfo
                                                    .countryCode === "IE"
                                                    ? 1000
                                                    : this.props.clientInfo
                                                          .countryCode === "US"
                                                    ? 1250
                                                    : this.props.clientInfo
                                                          .countryCode === "GB"
                                                    ? 900
                                                    : this.props.clientInfo
                                                          .countryCode === "CA"
                                                    ? 1550
                                                    : "1000"}
                                                </p>
                                        </div>
                                        <div className="col-6 text-left">
                                            <div className="col-12">
                                                <p className="text-green">
                                                    {this.getCurrencySymbol(
                                                        this.props.clientInfo
                                                            .countryCode
                                                    )}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-green">
                                                    per month
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <Link
                                                to="/contact"
                                                className="btn btn-primary"
                                            >
                                                Order Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card">
                                <div className="card-header text-center">
                                    <h3 className="pt-4">23</h3>
                                    <h4>
                                        <small>hours per month</small>
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <hr />
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <p className="mb-4">
                                                Suitable for expanding and
                                                larger businesses wishing to
                                                hand over responsibility
                                                entirely to Digigrow for one or
                                                a number of different services.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6 text-right">
                                            <p className="text-green">
                                                {this.props.clientInfo
                                                    .countryCode === "IE"
                                                    ? 1250
                                                    : this.props.clientInfo
                                                          .countryCode === "US"
                                                    ? 1550
                                                    : this.props.clientInfo
                                                          .countryCode === "GB"
                                                    ? 1150
                                                    : this.props.clientInfo
                                                          .countryCode === "CA"
                                                    ? 1950
                                                    : "1250"}
                                                </p>
                                        </div>
                                        <div className="col-6 text-left">
                                            <div className="col-12">
                                                <p className="text-green">
                                                    {this.getCurrencySymbol(
                                                        this.props.clientInfo
                                                            .countryCode
                                                    )}
                                                </p>
                                            </div>
                                            <div className="col-12">
                                                <p className="text-green">
                                                    per month
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 text-center">
                                            <Link
                                                to="/contact"
                                                className="btn btn-primary"
                                            >
                                                Order Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center footer">
                    <p className="text-muted">
                        <small>
                            Additional time or indiviual consulting hours
                            charged at a rate of €75/hour
                        </small>
                    </p>
                    <p className="text-muted">
                        <small>
                            Unused monthly hours may be carried forward for a
                            maximum of two additional months.
                        </small>
                    </p>
                </div>
            </div>
        );
    }
}

Pricing.propTypes = {
    getCountry: PropTypes.func.isRequired,
    clientInfo: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    clientInfo: state.pricing.clientInfo
});

export default connect(mapStateToProps, { getCountry })(Pricing);
