import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Assets
import calender from "../../assets/images/calendar.png";
import focus from "../../assets/images/seo-focus.png";
import what from "../../assets/images/what-SEO-ill.png";
import step1 from "../../assets/images/cta-illustr.png";
import tree from "../../assets/images/tree.png";

import "./seo.scss";

import Navbar from "../navbar/navbar.component";
import Pricing from "../pricing/pricing.component";
import WhySeo from "../whySeo/whySeo.component";
import HowWeHelp from "../howWeHelp/howWeHelp.component";
import WhatWeDo from "../whatWeDo/whatWeDo.component";
import Results from "../results/results.component";
import Footer from "../footer/footer.component";

class Seo extends Component {
    render() {
        return (
            <div className="container-fluid SEO">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>SEARCH ENGINE OPTIMIZATION (SEO)</h1>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center">
                        <h2 className="text-green">What is SEO?</h2>
                    </div>
                    <div className="col-sm-4 text-left">
                        <div className="block-container">
                            <p>
                                Search Engine Optimisation or SEO is all about
                                getting your business to the front page of
                                Google.
                            </p>
                            <img
                                src={what}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                        </div>
                        <div className="block-container">
                            <p>
                                <strong>
                                    Sounds simple right? Not so fast.
                                </strong>
                            </p>
                            <p>
                                Reaching the top of Google with your business is
                                a process that takes time and patience. With
                                over 600 algorithm changes per year, Google is
                                constantly updating what is considered to be
                                “good” SEO.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-2 show-everything-but-mobile text-center">
                        <img
                            src={tree}
                            alt="Search Engine Optimisation (SEO)"
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-sm-4 text-left">
                        <div className="block-container">
                            <img
                                src={calender}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                            <p>
                                By increasing your visibility you expose your
                                website, the digital calling card of your
                                business to more consumers who are looking for
                                your products or services. This increases
                                traffic to your website and improves your chance
                                of converting sales and customers.
                            </p>
                        </div>
                        <div className="block-container">
                            <img
                                src={focus}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                            <p>
                                SEO focuses on numerous aspects of your site
                                including content, technical, relevancy and most
                                important - user experience. These pieces need
                                to be consistently managed to improve and
                                maintain your search engine ranking.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 text-center">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <WhySeo />
                <HowWeHelp />
                <WhatWeDo type="seo"/>
                <Results />
                <Pricing />
                <div className="get-started-container">
                    <div className="col-12 get-started text-center">
                            <img
                                src={step1}
                                alt="Apply to 1:1 workshop with us"
                            />
                            <div className="text-container">
                                <h2 className="text-white">Get Started With Us</h2>
                                <Link
                                    to="/contact"
                                    className="btn btn-primary-inverted"
                                >
                                    Apply Now
                                </Link>
                            </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(null, {})(Seo);
