import React, { Component } from 'react';
import { connect } from 'react-redux';
import fast_loading from '../../assets/images/fast_loading.svg';
import about_us_directors from '../../assets/images/Phil&Ronan_About_Page.png';
import process from '../../assets/images/process.svg';
import './aboutUs.scss';

import Navbar from '../navbar/navbar.component';
import OurWork from '../ourWork/ourWork.component';
import OurClients from '../clients/ourClients.component';
import Footer from '../footer/footer.component';

class AboutUs extends Component {

    render() {
        return (
            <div className="container-fluid ABOUT_US">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>Get To Know Digigrow</h1>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 center-text">
                        <h2>About Digigrow</h2>
                        <p>
                            Digigrow is an Irish based Digital Marketing agency focused on growing the online presence of businesses through our unique Pay as you Grow service.
                        </p>
                        <p className="text-green">
                            Pay as you Grow defines data driven KPI’s specific to your business and aims to meet them with our Digigrow Guarantee.
                        </p>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={fast_loading} alt="About Digigrow" className="img-fluid" />
                    </div>
                </div>
                <div className="row content background-green">
                    <div className="col-sm-6 text-center">
                        <img src={about_us_directors} alt="History" className="img-fluid" />
                    </div>
                    <div className="col-sm-6 center-text">
                        <h2>History</h2>
                        <p>
                            Founded in 2016 by Dr. Philip Donnellan and Ronan O’Neill, Digigrow has sought to find new and creative solutions to marketing in the online space. With backgrounds in data processing and engineering, Digigrow’s founders determined that they could further increase sales and conversions for a customer when both creativity and technical are combined.
                        </p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 center-text">
                        <h2>Our Process</h2>
                        <p>
                            Digigrow utilises a team that consists of those who are focused on the development of strategic business growth and are coupled with the creativity to create and manage eye-catching and engaging strategies for the consumer. 
                        </p>
                        <p className="text-green">
                            Digigrow is a data-first, creative focused agency.
                        </p>
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={process} alt="Our Process" className="img-fluid" />
                    </div>
                </div>
                <OurWork />
                <OurClients />
                <Footer />
            </div>
        );
    }
}

export default connect(null, { })(AboutUs);