import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ourWork.scss';

import Navbar from '../navbar/navbar.component';
import OurWork from '../ourWork/ourWork.component';
import Footer from '../footer/footer.component';

class OurWorkPage extends Component {

    render() {
        return (
            <div className="container-fluid OUR_WORK">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>Explore Our Past Projects</h1>
                    </div>
                </div>
                <OurWork />
                <Footer />
            </div>
        );
    }
}

export default connect(null, { })(OurWorkPage);