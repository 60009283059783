import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { Switch, Route, Router } from "react-router-dom";
import { store } from "./store";
import history from "./history";

//Library Styles
import "dg-blocks/build/index.css";

import ScrollToTop from "./scrollToTop";
import ProtectedRoute from "./components/protectedRoute.component";
import Login from "./components/login/login.component";
import OurWork from "./components/ourWork/ourWorkPage.component";
import Services from "./components/services/services.component";
import AboutUs from "./components/aboutUs/aboutUs.component";
import Contact from "./components/contact/contactPage.component";
import CaseStudy from "./components/caseStudy/caseStudy.component";
import Home from "./components/home/home.component";
import SignUp from "./components/signup/signup.component";
import Blog from "./components/blog/pages/list.blog.component";
import BlogPost from "./components/blog/pages/post.blog.component";
import SEO from "./components/seo/seo.component";
import WebsiteDevelopment from "./components/websiteDevelopment/websiteDevelopment.component";
import SocialMedia from "./components/socialMedia/socialMedia.component";
import ContentCreation from "./components/contentCreation/contentCreation.component";
import PaidSearch from "./components/paidSearch/paidSearch.component";

// Admin
import PrivacyPolicy from "./components/privacyPolicy.component";
import PostListPage from "./components/admin/posts/postListPage.component";
import NewPostPage from "./components/admin/posts/newPostPage.component";
import EditPostPage from "./components/admin/posts/editPostPage.component";
import Leads from "./components/admin/leads/leads.component";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <ScrollToTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    {/*<Route path='/about-us' exact component={AboutUs} />*/}
                    <Route path="/services" exact component={Services} />
                    <Route path="/our-work" exact component={OurWork} />
                    <Route path="/contact" exact component={Contact} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/post" exact component={BlogPost} />
                    <Route path="/case-study" exact component={CaseStudy} />
                    <Route path="/signup" exact component={SignUp} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/seo" exact component={SEO} />
                    <Route
                        path="/website-development"
                        exact
                        component={WebsiteDevelopment}
                    />
                    <Route
                        path="/social-media-marketing"
                        exact
                        component={SocialMedia}
                    />
                    <Route
                        path="/content-creation"
                        exact
                        component={ContentCreation}
                    />
                    <Route path="/paid-search" exact component={PaidSearch} />
                    <Route
                        path="/privacy-policy"
                        exact
                        component={PrivacyPolicy}
                    />
                    <ProtectedRoute
                        path="/admin/posts"
                        exact
                        component={PostListPage}
                    />
                    <ProtectedRoute
                        path="/admin/new-post"
                        exact
                        component={NewPostPage}
                    />
                    <ProtectedRoute
                        path="/admin/post"
                        exact
                        component={EditPostPage}
                    />
                    <ProtectedRoute
                        path="/admin/leads"
                        exact
                        component={Leads}
                    />
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
