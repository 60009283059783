import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { MultiColumn, TwoColumn } from "dg-blocks";

//Assets
import why1 from "../../assets/images/5-sec.png";
import why2 from "../../assets/images/piechart-users.png";
import why3 from "../../assets/images/piechart-layout.png";
import why4 from "../../assets/images/dashboard.png";
import help1 from "../../assets/images/responsive-website.png";
import help2 from "../../assets/images/browser-deluxe-enviro.png";
import how1 from "../../assets/images/laptops.png";
import how2 from "../../assets/images/browser-rods-cones.png";
import how3 from "../../assets/images/rods-cones-tablet.png";

import branding from "../../assets/images/web-branding.png";
import design from "../../assets/images/web-design.png";
import webDev from "../../assets/images/web-dev.png";
import technical from "../../assets/images/technical-impl.png";
import chat from "../../assets/images/chat-bot.png";
import ecomm from "../../assets/images/e-comm-impl.png";
import enhance from "../../assets/images/web-enhance.png";
import seo from "../../assets/images/seo-optimise.png";
import support from "../../assets/images/web-support.png";
import hosting from "../../assets/images/hosting.png";

//Styles
import "./websiteDevelopment.scss";

import Navbar from "../navbar/navbar.component";
import Pricing from "../pricing/pricing.component";
import Footer from "../footer/footer.component";
import GetStarted from "../getStarted/getStarted.component";

let whyItems = [
    <>
        <div className="social-item flex">
            <img src={why1} alt="reason 1" className="img-fluid" />
            <h3>0.05</h3>
            <p>
                seconds is the amount of time it takes for someone to make a
                judgement about your website
            </p>
        </div>
        <div className="social-item flex">
            <img src={why2} alt="reason 2" className="img-fluid" />
            <h3>57%</h3>
            <p>
                of web users will not recommend a business with a poor mobile
                design
            </p>
        </div>
        <div className="social-item flex">
            <img src={why3} alt="reason 3" className="img-fluid" />
            <h3>38%</h3>
            <p>
                of people will not engage with a website that has a complicated
                or ugly layout
            </p>
        </div>
        <div className="social-item flex">
            <img src={why4} alt="reason 4" className="img-fluid" />
            <h3>2.6Bn</h3>
            <p>
                in lost sales are recorded per year due to a slow loading
                website
            </p>
        </div>
    </>
];
class Development extends Component {
    render() {
        return (
            <div className="websiteDevelopment">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>WEBSITE DEVELOPMENT</h1>
                    </div>
                </div>
                <div className="why-do-i-need">
                    <TwoColumn
                        containerClass="container-row padded-row flex"
                        rightColClass="flex col image-col"
                        rightColumn={
                            <img
                                src={help1}
                                className="img-fluid"
                                alt="why do i need a website"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <>
                                {" "}
                                <h2 className="text-green">
                                    Why do I need a website?
                                </h2>
                                <p className="primary">
                                    Having a website that is modern and up to
                                    date is imperative to your online presence.
                                    Similar to a physical store front, your
                                    website is often the first thing that a
                                    potential client or customer sees and acts
                                    as the calling card of your business.
                                </p>
                            </>
                        }
                    />
                </div>
                <div className="why-do-i-need">
                    <TwoColumn
                        containerClass="container-row mobile-reverse flex"
                        rightColClass="flex col deluxe"
                        rightColumn={
                            <>
                                {" "}
                                <p className="primary">
                                    If your website lack visual appeal, loads
                                    slowly, isn’t optimised for mobile or isn’t
                                    providing the right amount of information,
                                    your customers are likely to go elsewhere –
                                    no matter how much you might have spent on
                                    advertisements. A good website is an
                                    uncompromising requirement of your business.
                                </p>
                                <NavLink
                                    to="/contact"
                                    className="btn btn-primary"
                                >
                                    Lets Work Together
                                </NavLink>
                            </>
                        }
                        leftColClass="flex col image-col"
                        leftColumn={
                            <img
                                src={help2}
                                className="img-fluid deluxe-image"
                                alt="why do i need a website"
                            />
                        }
                    />
                </div>
                <MultiColumn
                    containerClass={"why-use"}
                    headerClass={"header-container flex"}
                    header={
                        <h2 className="text-green">
                            Why is a quality website important?
                        </h2>
                    }
                    posts={whyItems}
                    rowClass={"container-row"}
                    columnClass={"flex container"}
                    footerClass={"footer-container flex"}
                    footer={
                        <NavLink to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </NavLink>
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <div className="how-we-help">
                    <TwoColumn
                        containerClass="container-row flex"
                        rightColClass="flex col-wide"
                        rightColumn={
                            <img
                                src={how1}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col padded"
                        leftColumn={
                            <>
                                <h2 className="text-green">
                                    How Digigrow can help
                                </h2>
                                <p className="primary">
                                    When it comes to website design and user
                                    experience, Digigrow knows its stuff. Our
                                    team of creatives and web developers work to
                                    build completely bespoke, unique websites
                                    that are on brand for your business and
                                    guaranteed to have your customers engaged.
                                </p>
                            </>
                        }
                    />
                    <TwoColumn
                        containerClass="container-row flex"
                        rightColClass="flex col col-image"
                        rightColumn={
                            <img
                                src={how2}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <p className="primary">
                                We manage all facets from the concept inception
                                to implementation and develop SEO worth content
                                that is created to engage with your customers
                                and drive them towards your conversion goals.
                                We’ve worked with a multitude of businesses to
                                create smooth and sleek sites which are quick to
                                load an mobile-first in design.
                            </p>
                        }
                    />
                    <TwoColumn
                        containerClass="container-row reverse flex"
                        rightColClass="flex col col-image"
                        rightColumn={
                            <img
                                src={how3}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <>
                                <p className="primary">
                                    We start by analysing your current site and
                                    listening to your needs. We then develop a
                                    spec that covers all facets of your site
                                    from content, chat bots, e-commerce
                                    platforms and more. From there our design
                                    team creates beautiful visuals and our
                                    content team fills in the rest. From here
                                    our Digigrow developers take over and bring
                                    the website to life resulting in the perfect
                                    website for your budget and your business.
                                </p>
                                <NavLink
                                    to="/contact"
                                    className="btn btn-primary"
                                >
                                    Lets Work Together
                                </NavLink>
                            </>
                        }
                    />
                </div>
                <div className="whatWeDo">
                    <div className="col-sm-12 header-container text-center">
                        <h2 className="text-green">What we do</h2>
                    </div>
                    <div className="col-sm-12 cards-container">
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={branding}
                                    alt="branding concepts"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Website and branding concept</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={design}
                                    alt="website design"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Website design</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={webDev}
                                    alt="website development"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Website development</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={technical}
                                    alt="technical implementation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Technical implementations</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={chat}
                                    alt="Chat bot"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Chat bot implementation</p>
                        </div>
                    </div>
                    <div className="col-sm-12 cards-container">
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={ecomm}
                                    alt="ecommere platform creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>E-commerce platform creation & implementation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={enhance}
                                    alt="Website enhancement"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Website enhancement</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={seo}
                                    alt="seo optimised content"
                                    className="img-fluid"
                                />
                            </div>
                            <p>SEO optimised content creation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={support}
                                    alt="ongoing support"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Ongoing web support</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={hosting}
                                    alt="hosting"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Website hosting</p>
                        </div>
                    </div>
                    <div className="col-sm-12 footer-container text-center">
                        <NavLink to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </NavLink>
                    </div>
                </div>

                <Pricing />
                <GetStarted />
                <Footer />
            </div>
        );
    }
}

export default Development;
