import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./whatWeDo.scss";

//Assets
import analysis from "../../assets/images/analysis.png";
import coding from "../../assets/images/coding.png";
import audit from "../../assets/images/audit.png";
import creation from "../../assets/images/creation.png";
import strategy from "../../assets/images/strategy.png";
import keyword from "../../assets/images/keyword.png";
import seoPage from "../../assets/images/seo-page.png";
import research from "../../assets/images/research.png";
import link from "../../assets/images/link-b.png";
import updates from "../../assets/images/update.png";

class WhatWeDo extends Component {
    render() {
        return (
            <div className="whatWeDo">
                <div className="col-sm-12 text-center">
                    <h2 className="text-white">What we do</h2>
                </div>
                <div className="col-sm-12 cards-container">
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={audit}
                                alt="SEO audits"
                                className="img-fluid"
                            />
                        </div>
                        <p>Website Audit</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={updates}
                                alt="Website updates"
                                className="img-fluid"
                            />
                        </div>
                        <p>Website Updates</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={strategy}
                                alt="Content strategy"
                                className="img-fluid"
                            />
                        </div>
                        <p>Content Strategy</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={creation}
                                alt="Content creation"
                                className="img-fluid"
                            />
                        </div>
                        <p>Content Creation</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={analysis}
                                alt="Technical analysis"
                                className="img-fluid"
                            />
                        </div>
                        <p>Technical Analysis</p>
                    </div>
                </div>
                <div className="col-sm-12 cards-container">
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={coding}
                                alt="Technical implementation"
                                className="img-fluid"
                            />
                        </div>
                        <p>Technical Implementation</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={keyword}
                                alt="Keyword research"
                                className="img-fluid"
                            />
                        </div>
                        <p>Keyword Research</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={seoPage}
                                alt="On page optimization"
                                className="img-fluid"
                            />
                        </div>
                        <p>On Page Optimization</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={link}
                                alt="Link building"
                                className="img-fluid"
                            />
                        </div>
                        <p>Link Building</p>
                    </div>
                    <div className="card text-center">
                        <div className="img">
                            <img
                                src={research}
                                alt="Research"
                                className="img-fluid"
                            />
                        </div>
                        <p>Research</p>
                    </div>
                </div>
                <div className="col-sm-12 text-center">
                    <Link to="/contact" className="btn btn-primary">
                        Lets Work Together
                    </Link>
                </div>
            </div>
        );
    }
}

export default connect(null, {})(WhatWeDo);
