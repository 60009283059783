import { FETCH_POSTS, NEW_POST, SELECT_POST, CLEAR_POST_FORM } from '../actions/types';

const initialState = {
    items: [],
    item: {},
    currentPost: {},
    clearForm: true
};

export default function (state = initialState, action) {
    switch(action.type) {
        case FETCH_POSTS: 
            return {
                ...state,
                items: action.payload
            }
        case NEW_POST: 
            return {
                ...state,
                item: action.payload
            }
        case SELECT_POST: 
            return {
                ...state,
                currentPost: action.payload
            }
        case CLEAR_POST_FORM: 
            return {
                ...state,
                clearForm: action.payload
            }
        default:
            return state;
    }
}
