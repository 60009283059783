import React, { Component } from 'react';
import { connect } from 'react-redux';

import './caseStudy/caseStudy.scss';
import Navbar from './navbar/navbar.component';
import Footer from './footer/footer.component';

class PrivacyPolicy extends Component {

    render() {
        return (
            <div className="container-fluid CASE_STUDY">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                    </div>
                    <div className="col-12 text-center">
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>1. SCOPE AND PURPOSE</h2>
                        <p>
                        This document outlines to all data subjects, who interact with Digigrow, under what conditions their data will be processed, how Digigrow strives to ensure that the individual’s privacy is respected at all times, who conducts the processing, what security features are in place, and what rights the data subject retains at all times. Above all, this document aims to ensure that transparency exists with respect to Digigrow’s data processing.
                        </p>
                        <p>
                        If you have any questions in relation to this document or if you wish to exercise any of your rights concerning your personal data, please email us at <a href="mailto:info@digigrow.ie">info@digigrow.ie</a>.
                        </p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>2. WHAT DATA DO WE COLLECT AND WHY DO WE COLLECT IT?</h2>
                        <p>
                            We collect personal data of our employees, potential employees, clients, suppliers, business contacts and website users.
                            If the data we collect is not listed in this privacy statement, we will give individuals (when required by law) appropriate notice of which other data will be collected and how their data will be used.
                            Except for certain information that is required by law, your decision to provide any personal data to us is voluntary. You will therefore not be subject to adverse consequences if you do not wish to provide us with your personal data. However, please note that if you do not provide certain information, we may not be able to accomplish some or all of the purposes outlined in this privacy statement, and you may not be able to use certain tools and systems which require the use of such personal data.                        </p>
                        <p>
                            If you provide us with personal data of another person (for instance, a potential employee/referral), you are responsible for ensuring that such person is made aware of the information contained in this privacy statement and that the person has given you his/her consent for sharing the information with Digigrow.
                            The above-mentioned categories of personal data have been obtained either directly from you (for example, when you provide information to sign up for a newsletter or register for a webinar) or indirectly from certain third parties. Such third parties include our affiliates, public authorities, public websites and social media, suppliers and vendors.                        </p>
                    
                        <h3>1. Website user: events and webinars</h3>
                        <p>If you attend our online events or webinars, you may have completed a registration form online, through our Website or on LinkedIn. We will always strive to collect the minimum information required from you to ensure that we can efficiently and safely operate the activity or event itself. Typical examples of such information may be your name, your job title, your company, your e-mail address and your telephone number. Of course, there may be activities or events for which we will require some additional information or payment. In which case we will make sure that we inform you why any such information may be required.</p>
                    
                        <h3>2. Attendees at events and activities</h3>
                        <p>If you attend our events or activities, you will most likely have completed a registration form either on-line (see 2.1) or in person. We will always strive to collect the minimum information required from you to ensure that we can efficiently and safely operate the event itself. Typical examples of such information may be your name, your job title, your company, your e-mail address and your telephone number. Of course, there may be activities or events for which we will require some additional information, in which case we will make sure that we inform you why any such information may be required.</p>
                    
                        <h3>3. Website users: cookies</h3>
                        <p>If you use our website, then we will use cookies in order to identify that you are or have visited our site previously. Such cookies are referred to as session cookies and retain data such as your IP address and distinguishing individual users when on a shared IP address, these cookies provide no personal identifiable features to Digigrow and are used in conjunction with Google analytics to allow us monitor the performance of our website.</p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>3. HOW WE STORE YOUR DATA</h2>
                        <p>
                        Like most companies, Digigrow uses customer relationship management (CRM) database technology to manage and track our clients and marketing campaigns. Our CRM databases include personal data belonging to individuals at our client and other companies with whom we already have a business relationship or want to develop one. The personal data used for these purposes includes relevant business information, such as: contact data, publicly available information (e.g. your public posts on social media sites if relevant for business purpose), your responses to targeted e-mails (including web activity following links from our e-mails), and other business information included by Digigrow professionals based on their personal interactions with you. If you wish to be excluded from our CRM databases, please contact us.</p>
                        
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>4. MARKETING</h2>
                        <p>
                        Most of the personal data we collect and use for marketing purposes relates to individual employees of our clients and other companies with which we have an existing business relationship. We may also obtain contact information from public sources, including content made public at social media websites, to make an initial contact with a relevant individual at a client or other company.</p>
                        <p>
                        We send commercial e-mails to individuals at our clients or other companies with whom we want to develop or maintain a business relationship in accordance with applicable marketing laws. Our targeted e-mail messages may include web beacons and similar technologies that allow us to know whether you open, read, or delete the message, and links you may click. When you click a link in a marketing e-mail you receive from Digigrow, we will also use a cookie to log what pages you view and what content you download from our websites.</p>
                    
                        <h3>1. Your rights regarding marketing</h3>
                        <p>You can exercise your right to prevent marketing communications to you by checking certain boxes on the forms we use to collect your personal data, or by utilizing opt-out mechanisms in e-mails we send to you. You can also exercise the right to discontinue marketing communications to you, or to have your personal data removed from our customer relationship management (CRM) databases at any time by contacting us. In such cases, we will retain minimum personal data to note that you opted out in order to avoid contacting you again.</p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>5. HOW LONG DO WE RETAIN YOUR INFORMATION?</h2>
                        <p>
                        We will retain your personal data only for as long as is necessary. We maintain specific records management and retention policies and procedures, so that personal data is deleted after a reasonable time according to the following retention criteria:</p>
                        <ul>
                            <li>We retain your data as long as we have an ongoing relationship with you.</li>
                            <li>We will only keep the data while your consultation is active or for as long as needed to provide services to you.</li>
                            <li>We retain your data for as long as needed in order to comply with our legal and contractual obligations.</li>
                        </ul>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>6. WHO PROCESSES YOUR DATA?</h2>
                        <p>
                        Any data which you provide to use will be processed under conditions of absolute confidentiality and we will ensure that we do not share this information with anyone other than a select group of trusted partners. All of our partners have provided guarantees of GDPR compliance, so that we have confidence that your information will be processed with the highest standards of data privacy.</p>
                        <p>Under no conditions do we transfer any of the data which you provide us outside of the European Economic Area (EEA). If any of our third-party processing partners conduct any such transfers, we will ensure that they provide sufficient safeguards as required by GDPR to ensure that your data continues to receive the required levels of data protection.</p>
                        <p>We use third-party providers to host our websites and manage events and activities. We only ever use the standard services of the platforms and do not explicitly share any personal data with these third parties. Existing legislation may supersede retention periods such as financial information regarding receipts and payments will be retained for the previous 7 years.</p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>7. SECURITY OF YOUR INFORMATION</h2>
                        <p>
                        We take our information security responsibilities very seriously and employ the most appropriate physical and technical measures, including staff training and awareness, to ensure that your information is safe. These measures are regularly reviewed.</p>
                        
                        <h3>Training</h3>
                        <p>All members of the Digigrow team have received training relating to data security and the processing of personal information. Individuals have been additionally provided with training relating to the processing of data specific to their roles within Digigrow and the management of client data.</p>

                        <h3>Controlled access</h3>
                        <p>Administrative and technical controls are in place to restrict access to personal data to a 'need to know' basis.</p>

                        <h3>Physical security</h3>
                        <p>All of the data provided to Digigrow in either physical (paper) form or by digital means is stored securely on premise within the Digigrow offices.</p>

                        <h3>Encryption</h3>
                        <p>All data provided to Digigrow by digital means are encrypted in transit and also at rest. Encryption in transit is achieved by the use of SSL certificates, while encryption at rest is achieved on multiple levels, using both cloud based and application level encryption.</p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>8. YOUR RIGHTS</h2>
                        <p>
                        You have a number of rights in relation to your personal data. We have summarised those rights for you below</p>
                        
                        <h3>1. The Right of Access</h3>
                        <p>You have the right to be informed about which of your data we are currently processing, why we are processing it, and how this processing is taking place. As part of any such request, you may ask us to provide you with a copy of this data.</p>

                        <h3>2. Right to Portability</h3>
                        <p>You may wish for your data to be transferred to another data controller of your choice, in which case we will either pass this information to you (as outlined in section 4.1) or to the controller of your choice.</p>

                        <h3>3. Right of Rectification</h3>
                        <p>If you feel that data which we are processing pertaining to you is inaccurate, then please let us know, and we will fulfil your right to have it amended and rectified.</p>

                        <h3>4. Right to Erasure</h3>
                        <p>Under many conditions, you will have the right to be completely “forgotten” by the DSPCA. If you make such a request, we will strive to delete all of your data on our system and any correspondences which you have had with us.</p>

                        <h3>5. Right to Restrict Processing</h3>
                        <p>You have the right to tell us to stop processing your personal data for any reason. This means that while this request exists, we will still store your data, but won’t use it.</p>

                        <h3>6. Right to Object</h3>
                        <p>If you feel that we are processing your data in a way which is unlawful, excessive, or if you would simply like us to stop, you have the right to object to this processing.</p>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>9. COMPLAINTS</h2>
                        <p>
                        If you have any complaints about any data processing activity which are conducting relating to you or any information which you have supplied to us, please contact us and let us know (<a href="mailto:info@digigrow.ie">info@digigrow.ie</a>). We will do everything in our power to resolve the situation. You always reserve the right of course to make an official complaint to the Data Protection Commissioner’s Office.</p>
                    
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center align-self-center">
                        <h2>10. CHANGES TO OUR PRIVACY POLICY</h2>
                        <p>
                        We keep our privacy statement under regular review and we will place any updates on this web page. The privacy statement was last updated on 16th October 2018.</p>
                        
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}


export default connect(null, { })(PrivacyPolicy);