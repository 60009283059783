import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './signup.scss';
import { createLead } from '../../actions/lead.action';

import Navbar from '../navbar/navbar.component';
import Footer from '../footer/footer.component';

class SIGNUP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            submitted: false
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();

        const post = {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email
        };

        this.props.createLead(post)
        .then(res => {
            this.setState({ name: '', phone: '', email: '', submitted: true });
            setTimeout(() => {this.setState({submitted:false});}, 3000);
        })
        .catch(res => {alert('success')});
    }

    render() {
        return (
            <div className="container-fluid SIGNUP">
                <div className="row background-img-full">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>Sign Up now to be in with a chance to win a<br /> <span>FREE SEO analysis!</span></h1>
                        <div className="container">
                            <div className="row mb-4">
                                <div className="offset-sm-1 col-sm-10 col-12 text-center">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className={this.state.submitted ? "alert alert-success" : 'd-none'} role="alert">
                                                Thank you for entering!
                                            </div>
                                            <form onSubmit={this.onSubmit}>
                                                <div className="form-group row">
                                                    <div className="col-12 text-center">
                                                        <label htmlFor="email">What's your Email? </label>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <input type="email" className="form-control" name="email" placeholder="Enter your email address" value={this.state.email} onChange={this.onChange} required />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div> 
        );
    }
}

SIGNUP.propTypes = {
    createPost: PropTypes.func.isRequired
};

export default connect(null, { createLead })(SIGNUP);