import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MultiColumn, TwoColumn } from "dg-blocks";

//Assets
import paid1 from "../../assets/images/paid-search-illustr-1.png";
import paid2 from "../../assets/images/paid-search-illustr-2.png";
import paid3 from "../../assets/images/paid-search-illustr-3.png";
import tree from "../../assets/images/tree.png";

import why1 from "../../assets/images/paid-search-stat-1.png";
import why2 from "../../assets/images/paid-search-stat-2.png";
import why3 from "../../assets/images/paid-search-stat-3.png";
import why4 from "../../assets/images/paid-search-stat-4.png";
import how from "../../assets/images/how-paid-search.png";
import arrowLeft from "../../assets/images/arrow-down-left.png";
import arrowRight from "../../assets/images/arrow-down-right.png";

import development from "../../assets/images/ppc-campaign.png";
import optimisation from "../../assets/images/optmisation.png";
import implementation from "../../assets/images/conversion.png";
import enhancement from "../../assets/images/conv-enh.png";
import ad from "../../assets/images/ad-creation.png";
import kpi from "../../assets/images/kpi.png";
import audience from "../../assets/images/audience-analysis.png";
import keyword from "../../assets/images/keyword-pl.png";
import keyOptimisation from "../../assets/images/keyword-opt.png";
import tracking from "../../assets/images/data-track.png";

import visitors from "../../assets/images/visitors.png";
import click from "../../assets/images/ppc1.png";
import leads from "../../assets/images/lead-conversion.png";

//Styles
import "./paidSearch.scss";

import Navbar from "../navbar/navbar.component";
import Pricing from "../pricing/pricing.component";
import Footer from "../footer/footer.component";
import GetStarted from "../getStarted/getStarted.component";

let whyItems = [
    <>
        <div className="social-item flex">
            <img src={why1} alt="reason 1" className="img-fluid" />
        </div>
        <div className="social-item flex">
            <img src={why2} alt="reason 2" className="img-fluid" />
        </div>
        <div className="social-item flex">
            <img src={why3} alt="reason 3" className="img-fluid" />
        </div>
        <div className="social-item flex">
            <img src={why4} alt="reason 4" className="img-fluid" />
        </div>
    </>
];

let resultItems = [
    <>
        <div className="flex column">
            <div className="img">
                <img
                    src={visitors}
                    alt="Increase in website ranking"
                    className="img-fluid"
                />
                <h3>17'000</h3>
            </div>
            <p className="primary">
                visitors to site in 2019 <br />
                (single client)
            </p>
        </div>
    </>,
    <>
        <div className="flex column">
            <div className="img">
                <img
                    src={click}
                    alt="Increase in keyword ranking"
                    className="img-fluid"
                />
                <h3>7.5%</h3>
            </div>
            <p className="primary">
                click through rate <br />
                (single client)
            </p>
        </div>
    </>,
    <>
        <div className="flex column">
            <div className="img">
                <img
                    src={leads}
                    alt="Increase in error"
                    className="img-fluid"
                />
                <h3>287%</h3>
            </div>
            <p className="primary">
                increase in leads converted <br />
                (single client)
            </p>
        </div>
    </>
];

class PaidSearch extends Component {
    render() {
        return (
            <div className="paidSearch">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>PAID SEARCH</h1>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-12 text-center">
                        <h2 className="text-green">
                            What is paid search/pay per click advertising?
                        </h2>
                    </div>
                    <div className="col-sm-4 text-left">
                        <div className="block-container">
                            <p>
                                Paid Search advertising, often referred to as
                                PPC marketing consists of the development of ads
                                via Google Adwords or Bing Advertising which
                                looks to target your audience and put your
                                website and services in front of them based upon
                                the specific keywords they are searching for.
                                Paid search advertising is one of the most
                                effective forms of advertising and with millions
                                of searches being performed every day is one of
                                the best locations to target your audience
                                online. There are a variety of PPC advertising
                                options including text, display, remarketing and
                                more.
                            </p>
                            <img
                                src={paid2}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                        </div>
                        <div className="block-container">
                            <p>
                                There are a variety of PPC advertising options
                                including text, display, remarketing and more.
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-2 show-everything-but-mobile text-center">
                        <img
                            src={tree}
                            alt="Search Engine Optimisation (SEO)"
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-sm-4 text-left">
                        <div className="block-container">
                            <img
                                src={paid1}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                            <p>
                                Paid search advertising is one of the most
                                effective forms of advertising and with millions
                                of searches being performed every day is one of
                                the best locations to target your audience
                                online.
                            </p>
                            <img
                                src={paid3}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 text-center">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <MultiColumn
                    containerClass={"why-use"}
                    headerClass={"header-container flex"}
                    header={
                        <h2 className="text-green">
                            Why use paid search/pay per click advertising?
                        </h2>
                    }
                    posts={whyItems}
                    rowClass={"container-row"}
                    columnClass={"flex container"}
                    footerClass={"footer-container flex"}
                    footer={
                        <Link to="/contact" className="btn btn-primary">
                            Apply Now
                        </Link>
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <div className="how-we-help">
                    <div className="header-container flex">
                        <h2 className="text-green">How Digigrow can help</h2>
                    </div>
                    <TwoColumn
                        containerClass="container-row flex"
                        rightColClass="flex col"
                        rightColumn={
                            <>
                                <span className="item one">
                                    <p className="primary">
                                        When it comes to paid search
                                        advertising, it can easily get
                                        complicated. The team at Digigrow are
                                        experts in Google Adwords and paid
                                        search advertising and are consistently
                                        staying up to date on trends and changes
                                        that allow us to continue to optimise
                                        your ads and get you the most
                                        conversions
                                    </p>
                                </span>
                                <span className="item two">
                                    <img
                                        src={arrowRight}
                                        className="img-fluid arrowRight"
                                        alt="how we help"
                                    />
                                    <img
                                        src={arrowLeft}
                                        className="img-fluid arrowLeft"
                                        alt="how we help"
                                    />
                                    <p className="primary">
                                        Digigrow starts by analysing your
                                        business, competitors and audience which
                                        allows us to define which keywords are
                                        likely to generate the most appearances
                                        on Google. We then develop a data driven
                                        proven strategy to set your campaign
                                        budget and increase traffic to your
                                        website.
                                    </p>
                                </span>
                                <span className="item three">
                                    <p className="primary">
                                        Finally, we continually optimise your
                                        ads generating new text and display
                                        content to remain fresh and relevant
                                        ensuring that you are seen by your
                                        customers and audience. This is also
                                        completely backed by our Pay as you Grow
                                        guarantee.
                                    </p>
                                </span>
                            </>
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <img
                                src={how}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                    />
                    <div className="footer-container flex">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <div className="whatWeDo">
                    <div className="col-sm-12 text-center">
                        <h2 className="text-green">What we do</h2>
                    </div>
                    <div className="col-sm-12 cards-container">
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={development}
                                    alt="content development"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content development</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={optimisation}
                                    alt="content optimisation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content optimisation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={implementation}
                                    alt="Conversion implementation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Conversion implementation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={enhancement}
                                    alt="Conversion enhancement"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Conversion enhancement</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={ad}
                                    alt="ad creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Ad creation</p>
                        </div>
                    </div>
                    <div className="col-sm-12 cards-container">
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={kpi}
                                    alt="KPI creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>KPI creation and reporting</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={audience}
                                    alt="audience analysis"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Audience analysis</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={keyword}
                                    alt="keyword planning"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Keyword planning</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={keyOptimisation}
                                    alt="keyword optimisation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Keyword optimisation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={tracking}
                                    alt="data tracking"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Data tracking and analytics</p>
                        </div>
                    </div>
                    <div className="col-sm-12 text-center">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <MultiColumn
                    containerClass={"results"}
                    headerClass={"header-container flex"}
                    header={<h2 className="text-green">Our results</h2>}
                    posts={resultItems}
                    rowClass={"container-row flex"}
                    columnClass={"flex inner-col"}
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <Pricing />
                <GetStarted />
                <Footer />
            </div>
        );
    }
}

export default PaidSearch;
