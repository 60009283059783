import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import process from '../../assets/images/process.svg';
import './caseStudy.scss';
import { setCurrentClient } from '../../actions/client.action';
import { Link } from 'react-router-dom';

import Navbar from '../navbar/navbar.component';
import Footer from '../footer/footer.component';

class CaseStudy extends Component {

    getColSize(totalLength) {
        let colSize = 1

        if(totalLength%6 === 0) {
            colSize = 2;
        } else if(totalLength%5 === 0) {
            colSize = 2;
        } else if(totalLength%4 === 0) {
            colSize = 3;
        } else if(totalLength%3 === 0) {
            colSize = 4;
        } else if(totalLength%2 === 0) {
            colSize = 6;
        } else {
            colSize = 12;
        }

        return colSize;
    }

    render() {

        const header_3_stats = this.props.client.case_study.the_results.slice(0,3).map((result, index, array) => (
            <div key={`header_3_stats_${index}`} className="col-4 text-center">
                <p className="text-large text-green">{result.key}</p>
                <p>{result.value}</p>
            </div>
        ));

        const about_client = this.props.client.case_study.the_story.map((line, index, array) => (
            <p key={`about_client_${index}`}>{line}</p>
        ));

        const services_we_provided = this.props.client.case_study.services_we_provided.map((service, index, array) => (
            <div key={`services_we_provided_${index}`} className={`col-sm-${this.getColSize(array.length)} text-center`}>
                <img src={service.image} alt={service.name} className="img-fluid" />
                <h3>{service.name}</h3>
                <p>{service.content}</p>
            </div>
        ));

        const the_goal = this.props.client.case_study.the_goal.map((goal, index, array) => (
            <p key={`the_goal_${index}`}>{goal}</p>
        ));

        const the_solution = this.props.client.case_study.the_solution.map((solution, index, array) => (
            <p key={`the_solution_${index}`}>{solution}</p>
        ));

        const the_results = this.props.client.case_study.the_results.map((result, index, array) => (
            <div key={`the_results_${index}`} className={`col-sm-${this.getColSize(array.length)} text-center`}>
                <p><span>{result.key}</span></p>
                <p>{result.value}</p>
            </div>
        ));

        return (
            <div className="container-fluid CASE_STUDY">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                    </div>
                    <div className="col-12 text-center logo">
                        <img src={this.props.client.logo} alt={`${this.props.client.company_name} Logo`} className="img-fluid" />
                    </div>
                    <div className="col-12 text-center">
                        <h1>{this.props.client.company_name}</h1>
                    </div>
                    <div className="container">
                        <div className="row mb-4">
                            {header_3_stats}
                        </div>
                    </div>
                </div>
                <div className="row content">
                    <div className="col-sm-6 center-text align-self-center">
                        <h2>The Story</h2>
                        {about_client}
                    </div>
                    <div className="col-sm-6 text-center">
                        <img src={this.props.client.backgroundImage} alt={`${this.props.client.company_name} Products`} className="img-fluid" />
                    </div>
                </div>
                <div className="row content background-green">
                <div className="col-sm-6 center-text vertical-spacer align-self-center">
                        <p className="stat-number">{this.props.client.case_study.the_results[0].key}</p>
                        <p className="stat-text">{this.props.client.case_study.the_results[0].value}</p>
                    </div>
                    <div className="col-sm-6 center-text">
                        <hr className="horizontal-spacer" />
                        <h2>The Goal</h2>
                        {the_goal}
                    </div>
                </div>
                <div className="row content background-grey">
                    <div className="col-sm-6 text-center">
                        <img src={process} alt="Our Process" className="img-fluid" />
                    </div>
                    <div className="col-sm-6 center-text">
                        <h2>The Solution</h2>
                        {the_solution}
                    </div>
                </div>
                <div className="container content results-details">
                    <div className="row">
                        {services_we_provided}
                    </div>
                </div>
                <div className="row content background-orange">
                    <div className="col-12 text-center mb-4">
                        <h2>Achieved Result</h2>
                    </div>
                    {the_results}
                </div>
                <div className="row content testimonial">
                    <div className="col-sm-8 text-center align-self-center">
                        <p>
                            {this.props.client.quote}
                        </p>
                    </div>
                    <div className="col-sm-4 text-right align-self-center">
                        <img src={process} alt="Our Process" className="img-fluid" />
                        <p>- {this.props.client.contact_name}</p>
                        <p>{this.props.client.company_name}</p>
                    </div>
                </div>
                <div className="row content text-center background-green">
                    <div className="col-12">
                        <h2>Let's Grow Your Business Together</h2>
                    </div>
                    <div className="col-12">
                        <p>Would you like to see how Digigrow Pay as You Grow approach will work for your company?</p>
                        <p>Apply to 1:1 workshop and let’s set your KPIs!</p>
                    </div>
                    <div className="col-12">
                        <Link to="/contact" className="btn btn-primary">Apply Now</Link>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

CaseStudy.propTypes = {
    client: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    client: state.clients.currentClient
});

export default connect(mapStateToProps, { setCurrentClient })(CaseStudy);