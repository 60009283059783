import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./howWeHelp.scss";

//Assets
import howSeo1 from "../../assets/images/how-seo-illustr-1.png";
import howSeo2 from "../../assets/images/how-seo-illustr-2.png";

class HowWeHelp extends Component {
    render() {
        return (
            <div className="row howWeHelp">
                <div className="col-12 help-container">
                    <div className="text-center">
                        <h2 className="text-green">How Digigrow can help</h2>
                    </div>
                    <div className="col-12 row">
                        <div className="col-9 item">
                            <p>
                                Digigrow are experts in SEO. Our team focuses on
                                harnessing our knowledge and expertise in
                                data-driven results to increase rankings through
                                targeting your audience and enhancing the flow
                                of your website while combining technical
                                aspects to enhance Google crawling the site.
                            </p>
                        </div>
                        <div className="col-3 item">
                            <img
                                src={howSeo1}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-12 row">
                        <div className="col-5 item">
                            <img
                                src={howSeo2}
                                alt="Search Engine Optimisation (SEO)"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-7 item">
                            <p>
                                We use a variety of techniques and are always up
                                to date on the latest algorithm changes and
                                trends which allows us to focus on marrying
                                quality audience driven content with the
                                technical aspects that Google determines to be
                                imperative to a good quality website. From
                                creating engaging and SEO driven content, to
                                building backlinks to increase your authority
                                and developing new and engaging ways to convert
                                your users on site, we manage every facet of
                                your growth and guaranteeing you results.
                            </p>
                            <Link to="/contact" className="btn btn-primary">
                                Lets Work Together
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {})(HowWeHelp);
