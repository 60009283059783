import { FETCH_CLIENTS, NEW_CLIENT, SELECT_CLIENT, SET_CURRENT_CLIENT } from '../actions/types';

const initialState = {
    clients: [],
    currentClient: {},
    client: {}
};

export default function (state = initialState, action) {
    switch(action.type) {
        case FETCH_CLIENTS: 
            return {
                ...state,
                clients: action.payload
            }
        case NEW_CLIENT: 
            return {
                ...state,
                client: action.payload
            }
        case SELECT_CLIENT:
            return {
                ...state,
                clients: action.payload
            }
        case SET_CURRENT_CLIENT: 
            return {
                ...state,
                currentClient: action.payload
            }
        default:
            return state;
    }
}