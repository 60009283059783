import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MultiColumn, TwoColumn } from "dg-blocks";

//Assets
import content from "../../assets/images/content-mk-illustr.png";
import why1 from "../../assets/images/content-mk-stat-1.png";
import why2 from "../../assets/images/content-mk-stat-2.png";
import why3 from "../../assets/images/content-mk-stat-3.png";
import why4 from "../../assets/images/content-mk-stat-4.png";
import help1 from "../../assets/images/how-ct-mk-1.png";
import help2 from "../../assets/images/how-ct-mk-2.png";
import help3 from "../../assets/images/how-ct-mk-3.png";

import strategy from "../../assets/images/content-stg.png";
import dev from "../../assets/images/content-dev.png";
import audit from "../../assets/images/website-audit.png";
import calendar from "../../assets/images/content-calendar1.png";
import dissemination from "../../assets/images/content-distr.png";
import competition from "../../assets/images/competition-research.png";
import blog from "../../assets/images/blog.png";
import euro from "../../assets/images/euro.png";
import video from "../../assets/images/video-ad.png";
import webDev from "../../assets/images/web-dev.png";

//Styles
import "./contentCreation.scss";

import Navbar from "../navbar/navbar.component";
import Pricing from "../pricing/pricing.component";
import Footer from "../footer/footer.component";
import GetStarted from "../getStarted/getStarted.component";

let items = [
    <>
        <div className="content top flex">
            <p className="primary">
                Content marketing encompasses the creation of written and visual
                content to promote your business, products and/or services. This
                includes text, video and imagery and email marketing which spans
                across all digital platforms. Content is all about how your
                business is represented to your customer base.
            </p>
        </div>
        <div className="content middle flex">
            <img
                src={content}
                className="img-fluid"
                alt="what is content marketing"
            />
        </div>
        <div className="content bottom flex">
            <p className="primary">
                A cohesive content marketing strategy is imperative for any
                business. It helps to keep your brand in line with your services
                across social media, Google Adwords, your website, and more.
            </p>
        </div>
    </>
];

let whyItems = [
    <>
        <div className="social-item flex">
            <img src={why1} alt="reason 1" className="img-fluid" />
            <h3>66%</h3>
            <p>increase in leads generated when video ads are used</p>
        </div>
        <div className="social-item flex">
            <img src={why2} alt="reason 2" className="img-fluid" />
            <h3>84%</h3>
            <p>
                of people find the usage of images on website and content to be
                important
            </p>
        </div>
        <div className="social-item flex">
            <img src={why3} alt="reason 3" className="img-fluid" />
            <h3>51%</h3>
            <p>
                of companies find that updating content has proven an efficient
                marketing tactic
            </p>
        </div>
        <div className="social-item flex">
            <img src={why4} alt="reason 4" className="img-fluid" />
            <h3>77%</h3>
            <p>
                of companies claim to have an implemented content marketing
                strategy
            </p>
        </div>
    </>
];

class ContentCreation extends Component {
    render() {
        return (
            <div className="contentCreation">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                        <h1>CONTENT CREATION</h1>
                    </div>
                </div>
                <MultiColumn
                    containerClass={"what-is-content-marketing flex"}
                    headerClass={"header-container flex"}
                    header={
                        <h2 className="text-green">
                            What is Content Marketing
                        </h2>
                    }
                    posts={items}
                    rowClass={"container-row flex"}
                    columnClass={"flex"}
                    footerClass={"footer-container flex"}
                    footer={
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <MultiColumn
                    containerClass={"why-use"}
                    headerClass={"header-container flex"}
                    header={
                        <h2 className="text-green">
                            What is Social Media Marketing?
                        </h2>
                    }
                    posts={whyItems}
                    rowClass={"container-row"}
                    columnClass={"flex container"}
                    footerClass={"footer-container flex"}
                    footer={
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    }
                    errorClass={"error-container"}
                    errorMessage={<h3>No Items Available</h3>}
                />
                <div className="how-we-help">
                    <div className="header-container flex">
                        <h2 className="text-green">How Digigrow can help</h2>
                    </div>
                    <TwoColumn
                        containerClass="container-row flex"
                        rightColClass="flex col"
                        rightColumn={
                            <img
                                src={help1}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <p className="primary">
                                The Digigrow team are a group of innovative and
                                creative content marketers who utilise language
                                and design to engage with your audience via
                                multiple platforms. Whether it’s updating and
                                refreshing content on your website, creating an
                                engaging blog piece or designing eye catching
                                advertisements to be displayed across the web.
                            </p>
                        }
                    />
                    <TwoColumn
                        containerClass="container-row reverse flex"
                        rightColClass="flex col"
                        rightColumn={
                            <img
                                src={help2}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <p className="primary">
                                Digigrow develops organic and paid content
                                calendars that ensure that your content is
                                consistently being shared across the website
                                whether on social platforms or your website. Our
                                development is based upon your business and our
                                data-driven proven strategies for best posting
                                practices that help you to stand our amongst the
                                competition.
                            </p>
                        }
                    />
                    <TwoColumn
                        containerClass="container-row flex"
                        rightColClass="flex col"
                        rightColumn={
                            <img
                                src={help3}
                                className="img-fluid"
                                alt="how we help"
                            />
                        }
                        leftColClass="flex col"
                        leftColumn={
                            <p className="primary">
                                With a quality content marketing strategy your
                                business and brand works cohesively to drive
                                customers through to your website.
                            </p>
                        }
                    />
                    <div className="footer-container flex">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>
                <div className="whatWeDo">
                    <div className="col-sm-12 header-container text-center">
                        <h2 className="text-green">What we do</h2>
                    </div>
                    <div className="col-sm-12 cards-container">
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={strategy}
                                    alt="content strategy"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content strategy</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={dev}
                                    alt="content development"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content development</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={audit}
                                    alt="Content audits"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content and website audits</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={calendar}
                                    alt="Content calendar creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content calendar creation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={dissemination}
                                    alt="Content dissemination"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Content dissemination </p>
                        </div>
                    </div>
                    <div className="col-sm-12 cards-container">
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={competition}
                                    alt="Competition research"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Competitor content research</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={blog}
                                    alt="Blog and article creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Blog and article creation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={euro}
                                    alt="Organic and sponsored post creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Organic and sponsored post creation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={video}
                                    alt="video ad creation"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Video ad creation</p>
                        </div>
                        <div className="card text-center">
                            <div className="img">
                                <img
                                    src={webDev}
                                    alt="Website development"
                                    className="img-fluid"
                                />
                            </div>
                            <p>Website content development </p>
                        </div>
                    </div>
                    <div className="col-sm-12 footer-container text-center">
                        <Link to="/contact" className="btn btn-primary">
                            Lets Work Together
                        </Link>
                    </div>
                </div>

                <Pricing />
                <GetStarted />
                <Footer />
            </div>
        );
    }
}

export default ContentCreation;
