import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Facebook from "../../assets/images/facebook.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import Twitter from "../../assets/images/twitter.svg";
import Instagram from "../../assets/images/instagram.svg";
import Email from "../../assets/images/email_footer.svg";
import Phone from "../../assets/images/telephone_footer.svg";
import Location from "../../assets/images/location_footer.svg";
import "./footer.scss";

class Footer extends Component {
    render() {
        const year = new Date().getFullYear();

        return (
            <footer>
                <div className="row FOOTER_TOP">
                    <div className="col-lg-3 col-md-3 col-sm-3">
                        <div className="row">
                            <div className="col-12">
                                <p style={{ fontWeight: "bold" }}>
                                    &copy; Digigrow Limited {year}
                                </p>
                            </div>
                            <div className="spacer"></div>
                            <div className="col-12">
                                <p>Company Registration Number: 591197</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3">
                        <div className="row">
                            <div className="col-3">
                                <img
                                    src={Email}
                                    alt="Email"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-9">
                                <a href="mailto:info@digigrow.ie">
                                    info@digigrow.ie
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3">
                        <div className="row">
                            <div className="col-3">
                                <img
                                    src={Phone}
                                    alt="Phone"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-9">
                                <a href="tel:+18573475735">
                                    USA: +1 857 347 5735
                                </a>
                            </div>
                        </div>
                        <div className="row margin-small">
                            <div className="col-3">
                            </div>
                            <div className="col-9">
                                <a href="tel:+35312118550 ">
                                    Ireland: +353 1 211 8550
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3">
                        <div className="row">
                            <div className="col-3">
                                <img
                                    src={Location}
                                    alt="Location"
                                    className="img-fluid"
                                />
                            </div>
                            <div className="col-9">
                                <p>
                                    100 Cambridge Street, 14th Floor, Boston, MA
                                    02114
                                </p>
                            </div>
                        </div>
                        <div className="row margin-small">
                            <div className="col-3">
                            </div>
                            <div className="col-9">
                                <p>
                                    Level 1, The Chase, Arkle Road, Sandyford
                                    Industrial Estate, Dublin 18, D18Y3X2
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-2 socials">
                        <div className="row">
                            <div className="col-3 text-center">
                                <a href="https://www.linkedin.com/company/digigrow">
                                    <img
                                        src={LinkedIn}
                                        alt="LinkedIn"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="col-3 text-center">
                                <a href="https://www.facebook.com/digigrow/">
                                    <img
                                        src={Facebook}
                                        alt="Facebook"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="col-3 text-center">
                                <a href="https://twitter.com/digigrow_ie">
                                    <img
                                        src={Twitter}
                                        alt="Twitter"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="col-3 text-center">
                                <a href="https://www.instagram.com/digigrow.ie/">
                                    <img
                                        src={Instagram}
                                        alt="Instagram"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row FOOTER_BOTTOM">
                    <div className="col-12">
                        <ul className="nav justify-content-center">
                            <li className="nav-item">
                                <Link className="nav-link" to="/privacy-policy">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">
                                    <span>|</span>
                                    Terms of Use
                                    <span>|</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/">
                                    Cookies Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        );
    }
}

export default connect(null, {})(Footer);
