import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Star_checked from '../../assets/images/star_checked.svg';
import './clientCarousel.scss';
import { fetchClients } from '../../actions/client.action';

class ClientCarousel extends Component {

    componentDidMount() {
        this.props.fetchClients();
    }

    render() {

        const clients_page1 = this.props.clients.slice(0,3).map((client, index) => (
            <div key={`clients_page1_${index}`} className="col-lg-4 col-md-6 col-sm-4 text-center">
                <img src={client.logo_color} alt={client.company_name} className="img-fluid logo" />
                <div className="rating">
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                </div>
                <p className="text-green">
                    {client.quote}
                </p>
                <p>- {client.contact_name} | {client.company_name}</p>
            </div>
        ));

        const clients_page2 = this.props.clients.slice(3,6).map((client, index) => (
            <div key={`clients_page2_${index}`} className="col-lg-4 col-md-6 col-sm-4 text-center">
                <img src={client.logo_color} alt={client.company_name} className="img-fluid logo" />
                <div className="rating">
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                    <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                </div>
                <p className="text-green">
                    {client.quote}
                </p>
                <p>- {client.contact_name} | {client.company_name}</p>
            </div>
        ));

        const mobileCarousel_pages = this.props.clients.map((client, index) => (
            <div key={`clients_pages_${index}`} className={index === 0 ? "carousel-item active":"carousel-item"}>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-4 text-center">
                        <img src={client.logo_color} alt={client.company_name} className="img-fluid logo" />
                        <div className="rating">
                            <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                            <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                            <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                            <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                            <img src={Star_checked} alt={client.company_name} className="img-fluid" />
                        </div>
                        <p className="text-green">
                            {client.quote}
                        </p>
                        <p>- {client.contact_name} | {client.company_name}</p>
                    </div>
                </div>
            </div>
        ));

        const mobileCarousel_indicators = this.props.clients.map((client, index) => (
            <li key={`clientCaroursel_Mobile_${index}`} data-target="#clientCaroursel_Mobile" data-slide-to={`${index}`}  ></li>
        ))

        return (
            <div className="row OUR_CLIENTS_SAY">
                <div className="col-12 text-center">
                    <h2 className="text-green">Our Clients Say</h2>
                </div>
                <div className="col-12">
                    <div id="carouselExampleCaptions" className="carousel slide show-everything-but-mobile" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
                            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    {clients_page1}
                                </div>
                                
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    {clients_page2}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="clientCaroursel_Mobile" className="carousel slide show-mobile-only" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {mobileCarousel_indicators}
                        </ol>
                        <div className="carousel-inner">
                            {mobileCarousel_pages}
                        </div>
                        <a className="carousel-control-prev" href="#clientCaroursel_Mobile" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#clientCaroursel_Mobile" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

ClientCarousel.propTypes = {
    fetchClients: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    newClient: PropTypes.object
}

const mapStateToProps = state => ({
    clients: state.clients.clients,
    newClient: state.clients.client
});

export default connect(mapStateToProps, { fetchClients })(ClientCarousel);