import { FETCH_CLIENTS, NEW_CLIENT, SELECT_CLIENT, SET_CURRENT_CLIENT } from './types';

export const fetchClients = () => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/clients/`)
        .then(res => res.json())
        .then(clients => {
            dispatch({
                type: FETCH_CLIENTS,
                payload: clients
            });

            resolve();
        });
    });
};

export const createClient = (clientData) => dispatch => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/clients/create`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(clientData)
    })
    .then(res => res.json())
    .then(client => dispatch({
        type: NEW_CLIENT,
        payload: client
    }));
};

export const selectClient = (clientData) => dispatch => {
    dispatch({
        type: SELECT_CLIENT,
        payload: clientData
    });
};

export const setCurrentClient = (clientData) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_CURRENT_CLIENT,
            payload: clientData
        });

        resolve(true);
    });
    
};