import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import arrow from '../../assets/images/arrow.svg';
import arrow_swirl from '../../assets/images/arrow_swirl.svg';
import growth_analytics from '../../assets/images/growth_analytics.svg';
import order_confirmed from '../../assets/images/order_confirmed.svg';
import seo_active from '../../assets/images/seo_active.svg';
import seo_inactive from '../../assets/images/seo_inactive.svg';
import search_active from '../../assets/images/search_active.svg';
import search_inactive from '../../assets/images/search_inactive.svg';
import social_media_inactive from '../../assets/images/social_media_inactive.svg';
import content_creation_active from '../../assets/images/content_creation_active.svg';
import content_creation_inactive from '../../assets/images/content_creation_inactive.svg';
import website_creation from '../../assets/images/website_creation.svg';
import step1 from '../../assets/images/step1.svg';
import step2 from '../../assets/images/step2.svg';
import step3 from '../../assets/images/step3.svg';
import stats_home from '../../assets/images/stats_home.svg';
import home_background from '../../assets/images/home_background.png';
import headerVideo from '../../assets/videos/home_bg_video.mp4';
import './home.scss';

import Navbar from '../navbar/navbar.component';
import OurWork from '../ourWork/ourWork.component';
import Pricing from '../pricing/pricing.component';
import ClientCarousel from '../clients/clientCarousel.component';
import Contact from '../contact/contact.component';
import Footer from '../footer/footer.component';

class Home extends Component {

    constructor(props) {
        super(props);

        this.payg = React.createRef();
    }
    
    render() {
        return (
            <div className="container-fluid home">
                <div className="row header">
                    <div className="overlay"></div>
                    <video id="background-video" loop autoPlay muted poster={home_background}>
                        <source src={headerVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="nav-container col-12 text-center align-self-top">
                        <Navbar />
                    </div>
                    <div className="col-12 text-center align-self-center">
                        <button 
                            className="btn btn-primary"
                            onClick={() => {window.scrollTo(0, this.payg.current.offsetTop)}}
                        >
                            Pay As You Grow
                        </button>
                        <p>
                            Digital Marketing Services from Digigrow
                            <br />
                            SEO | Social Media | Content Creation | Website | PPC
                        </p>
                    </div>
                    <div className="col-12 text-center align-self-end">
                        <img src={arrow} alt="Arrow to content" className="bounce" />
                    </div>
                </div>
                <div className="row PAYG_IS">
                    <div className="col-12 text-center p-4" ref={this.payg} >
                        <h2 className="text-orange">Pay As You Grow Is:</h2>
                    </div>
                    <div className="col-sm-5 text-right">
                        <img src={growth_analytics} alt="We set data-driven goals for your business" className="img-fluid" />
                        <p>We set data-driven goals for your business</p>
                    </div>
                    <div className="col-sm-2 text-center">
                        <img src={arrow_swirl} alt="Arrow swirl right" className="img-fluid" />
                    </div>
                    <div className="col-sm-5 text-left">
                        <img src={order_confirmed} alt="Pay for what is achieved" className="img-fluid" />
                        <p>Pay for what is achieved</p>
                    </div>
                </div>
                <OurWork />
                <div className="row PAYG_SERVICES">
                    <div className="col-12 text-center">
                        <h2 className="text-green">Pay As You Grow Services</h2>
                    </div>
                    <div className="col-sm col-6 text-center">
                        <Link to="/seo">
                            <img src={seo_inactive} alt="SEO" className="img-fluid"
                            onMouseOver={e => (e.currentTarget.src = seo_active)}
                            onMouseOut={e => (e.currentTarget.src = seo_inactive)} 
                            />
                            <p>SEO</p>
                        </Link>
                    </div>
                    <div className="col-sm col-6 text-center">
                        <Link to="/paid-search">
                            <img src={search_inactive} alt="Paid Search" className="img-fluid"
                            onMouseOver={e => (e.currentTarget.src = search_active)}
                            onMouseOut={e => (e.currentTarget.src = search_inactive)} 
                            />
                            <p>Paid Search</p>
                        </Link>
                    </div>
                    <div className="col-sm col-6 text-center">
                        <Link to="/social-media-marketing">
                            <img src={social_media_inactive} alt="Social Media Marketing" className="img-fluid"
                            onMouseOver={e => (e.currentTarget.src = social_media_inactive)}
                            onMouseOut={e => (e.currentTarget.src = social_media_inactive)} 
                            />
                            <p>Social Media Marketing</p>
                        </Link>
                    </div>
                    <div className="col-sm col-6 text-center">
                        <Link to="/content-creation">
                            <img src={content_creation_inactive} alt="Content Creation" className="img-fluid"
                            onMouseOver={e => (e.currentTarget.src = content_creation_active)}
                            onMouseOut={e => (e.currentTarget.src = content_creation_inactive)} 
                            />
                            <p>Content Creation</p>
                        </Link>
                    </div>
                    <div className="col-sm col-6 text-center">
                        <Link to="/website-development">
                            <img src={website_creation} alt="Website Creation" className="img-fluid"
                            onMouseOver={e => (e.currentTarget.src = website_creation)}
                            onMouseOut={e => (e.currentTarget.src = website_creation)} 
                            />
                            <p>Web Development</p>
                        </Link>
                    </div>
                </div>
                <div className="row STATISTICS_HOME">
                    <div className="col-12">
                        <div className="container">
                            <div className="row align-items-end justify-content-center">
                                <div className="col-sm-2 col-6 text-center">
                                    <p className="figure-large">750%</p>
                                    <p>increase in organic traffic</p>
                                </div>
                                <div className="col-sm-2 col-6 text-center">
                                    <p className="figure-large">500%</p>
                                    <p>increase in social media engagement</p>
                                </div>
                                <div className="col-sm-2 col-6 text-center">
                                    <p className="figure-large">400%</p>
                                    <p>increase in conversion rates</p>
                                </div>
                                <div className="col-sm-6 col-12 text-center stats-img" style={{flex: '0 1 auto',width: 'unset'}}>
                                    <img src={stats_home} alt="Statistics" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pricing />
                <div className="row GET_STARTED_WITH_US">
                    <div className="col-12 text-center">
                        <h2>Get Started With Us</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 mt-4 mb-4 text-center">
                                <img src={step1} alt="Apply to 1:1 workshop with us" />
                                <p>Apply to 1:1 workshop with us</p>
                            </div>
                            <div className="col-sm-4 mt-4 mb-4 text-center">
                                <img src={step2} alt="Let’s set your KPIs together" />
                                <p>Let’s set your KPIs together</p>
                            </div>
                            <div className="col-sm-4 mt-4 mb-4 text-center">
                                <img src={step3} alt="Pay as you grow!Not More." />
                                <p>Pay as you grow!<br/>Not more</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-4 text-center">
                        <Link to="/contact" className="btn btn-primary-inverted">Apply Now</Link>
                    </div>
                </div>
                <ClientCarousel />
                <Contact />
                <Footer />
            </div>
        );
    }
}

export default Home;
