import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Facebook from "../../assets/images/facebook.svg";
import LinkedIn from "../../assets/images/linkedin.svg";
import Twitter from "../../assets/images/twitter.svg";
import Instagram from "../../assets/images/instagram.svg";
import "./navbar.scss";

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showWhiteLogo: false,
            isServicesOpen: false
        };
    }

    componentWillMount() {
        this.showWhiteLogo();
    }

    showWhiteLogo() {
        if (this.props.location.pathname === "/contact") {
            this.setState({ showWhiteLogo: true });
        }
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <NavLink className="navbar-brand mr-4" to="/">
                    <div
                        className={
                            this.state.showWhiteLogo
                                ? "logo-background"
                                : "d-none"
                        }
                    >
                        <img src={logo} alt="Logo" className="img-fluid" />
                    </div>
                    <img
                        src={logo}
                        alt="Logo"
                        className={
                            !this.state.showWhiteLogo ? "img-fluid" : "d-none"
                        }
                    />
                    <span className="ml-4">Digigrow</span>
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav m-auto">
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/">
                                Home <span className="sr-only">(current)</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/our-work">
                                Our Work
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" to="/about-us">About Us</NavLink>
                        </li>*/}
                        <li
                            className="nav-item dropdown-container"
                            onClick={() =>
                                this.setState({
                                    isServicesOpen: !this.state.isServicesOpen
                                })
                            }
                        >
                            <p className="nav-link">Services</p>
                        </li>
                        <ul
                            className={`sub-menu ${
                                this.state.isServicesOpen ? `sub-show` : ""
                            }`}
                        >
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/services">
                                    Our Services
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/seo">
                                    SEO
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/social-media-marketing"
                                >
                                    Social Media Marketing
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/content-creation"
                                >
                                    Content Creation
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/paid-search">
                                    Paid Search
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/website-development"
                                >
                                    Website Development
                                </NavLink>
                            </li>
                        </ul>
                        {/*
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/blog">
                                Blog
                            </NavLink>
                        </li>
                        */}
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">
                                Contact
                            </NavLink>
                        </li>
                    </ul>

                    <div className="navbar-nav ml-auto socials">
                        <div className="row">
                            <div className="col">
                                <a href="https://www.linkedin.com/company/digigrow">
                                    <img
                                        src={LinkedIn}
                                        alt="LinkedIn"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="col">
                                <a href="https://www.facebook.com/digigrow/">
                                    <img
                                        src={Facebook}
                                        alt="Facebook"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="col">
                                <a href="https://twitter.com/digigrow_ie">
                                    <img
                                        src={Twitter}
                                        alt="Twitter"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="col">
                                <a href="https://www.instagram.com/digigrow.ie/">
                                    <img
                                        src={Instagram}
                                        alt="Instagram"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withRouter(connect(null, {})(Navbar));
