import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './clients.scss';
import { fetchClients } from '../../actions/client.action';
import { Link } from 'react-router-dom';

class OurClients extends Component {

    componentDidMount() {
        this.props.fetchClients();
    }
    

    render() {
        const clientLogos = this.props.clients.map(client => (
            <div key={client.id} className="col-sm-2 col-4 mb-4 align-self-center text-center">
                <img src={client.logo} alt={client.company_name} className="img-fluid" />
            </div>
        ));

        return (
            <div className="row OUR_CLIENTS">
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mb-4">
                                <h2>Our Clients</h2>
                            </div>
                            {clientLogos}
                            <div className="col-12 text-center">
                                <p>
                                    Each client who works with Digigrow receives a completely unique plan that is designed around the goals and vision of the business. Having executed successful marketing campaigns globally, Digigrow’s Pay as you Grow services speak for themselves in their track record of success.
                                </p>
                                <Link to="/contact" className="btn btn-primary">Let's Work Together</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}

OurClients.propTypes = {
    fetchClients: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    newClient: PropTypes.object
}

const mapStateToProps = state => ({
    clients: state.clients.clients,
    newClient: state.clients.client
});

export default connect(mapStateToProps, { fetchClients })(OurClients);