import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import './WYSIWUG.scss';

class WYSIWUG_Editor extends Component {

    constructor(props) {
        super(props);
        this.state = {
          editorState: EditorState.createEmpty()
        };

        this.props.input.onChange(
            draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        );
    }

    componentDidMount() {
        console.log(this.props.clearForm);
        if(this.props.clearForm) {
            const contentBlock = htmlToDraft(this.props.currentPost);
        
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({editorState: editorState});
            }
        }
    }

    onEditorStateChange = (editorState) => {
        const { input: { onChange, value } } = this.props;
    
        const newValue = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

        console.log(`Value :: ${value}`);
        console.log(`NewValue :: ${newValue}`);
        if(value !== newValue) {
            onChange(newValue);
        }
        
        this.setState({
            editorState
        });
    };

    render() {
        const { editorState } = this.state;

        return (
            <div className="WYSIWUG_Editor">
                <Editor 
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                        image: {
                            previewImage: true
                        }
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentPost: state.posts.currentPost.wysiwyg,
    clearForm: state.posts.clearForm
});

export default connect(mapStateToProps, { })(WYSIWUG_Editor);