import React, { Component } from 'react';
import { connect } from 'react-redux';
import './contact.scss';

import ContactButtons from './contactButtons.component';

class Contact extends Component {

    render() {
        return (
            <div className="row CONTACT">
                <div className="col-12 background-img mb-4 d-flex">
                    <ContactButtons />
                </div>
            </div>
        );
    }
}

export default connect(null, { })(Contact);