import { NEW_LEAD_PHONE, GET_LEADS } from './types';

export const createLead = (postData) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/leads/create`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postData)
        })
        .then(res => res.json())
        .then(post => {

            dispatch({
                type: NEW_LEAD_PHONE,
                payload: post
            });

            resolve(true);
        });
    });
};

export const getLeads = (postData) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/leads/`)
        .then(res => res.json())
        .then(post => {

            dispatch({
                type: GET_LEADS,
                payload: post
            });

            resolve(true);
        });
    });
};