import history from './history';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleware = [thunk];

const initalState = '';

const rootReducer = createRootReducer(history);

export const store = createStore(
    rootReducer,
    initalState,
    compose(
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history),
                ...middleware
            )
        )
    )
);
