import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './list.posts.scss';
import { fetchPosts, selectPost } from '../../actions/post.action';
import history from '../../history';

import testImage from '../../assets/images/header_background_larger.png';

class PostList extends Component {

    componentDidMount() {
        this.props.fetchPosts();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.newPost) {
            this.props.posts.unshift(nextProps.newPost);
        }
    }

    selectPost(post) {
        this.props.selectPost(post);
        history.push(`/blog/${post._id}`);
    }

    render() {
        let posts;
        if(this.props.numPosts) {
            posts = this.props.posts.slice(0, this.props.numPosts).map((post, index) => (
                <div className="col-sm-4 mt-4 mb-4">
                    <div className="blog-post text-center">
                        <p className="tags">#digitalMarketing</p>
                        <div className="cover-img">
                            <div className="overlay">
                                <h2>{post.title}</h2>
                            </div>
                            
                            <img src={testImage} alt="Location" className="img-fluid" />
                        </div>
                        <p className="content-snippet">When you’re running a SMB, there just never seems to be enough time. When we start working with our clients. When you’re running a SMB, there just never seems to be enough time. When we start working with our client</p>
                        <button className="btn btn-primary" onClick={() => this.selectPost(post)} >Read More</button>
                    </div>
                </div>
            ));
        } else {
            posts = this.props.posts.map((post, index) => (
                <div className="col-sm-4 mt-4 mb-4">
                    <div className="blog-post text-center">
                        <p className="tags">#digitalMarketing</p>
                        <div className="cover-img">
                            <div className="overlay">
                                <h2>{post.title}</h2>
                            </div>
                            
                            <img src={testImage} alt="Location" className="img-fluid" />
                        </div>
                        <p className="content-snippet">When you’re running a SMB, there just never seems to be enough time. When we start working with our clients. When you’re running a SMB, there just never seems to be enough time. When we start working with our client</p>
                        <button className="btn btn-primary" onClick={() => this.selectPost(post)} >Read More</button>
                    </div>
                </div>
            ));
        }
        

        return (
            <div className="row POST_LIST">
                {posts}
            </div>
        );
    }
}

PostList.propTypes = {
    fetchPosts: PropTypes.func.isRequired,
    selectPost: PropTypes.func.isRequired,
    posts: PropTypes.array.isRequired,
    newPost: PropTypes.object
}

const mapStateToProps = state => ({
    posts: state.posts.items,
    newPost: state.posts.item
});

export default connect(mapStateToProps, { fetchPosts, selectPost })(PostList);
