import React, { Component } from 'react';
import { connect } from 'react-redux';
import './list.blog.scss';

import PostList from '../list.posts.component';

import Navbar from '../../navbar/navbar.component';
import Footer from '../../footer/footer.component';

class BlogList extends Component {

    render() {

        return (
            <div className="container-fluid BLOG_LIST">
                <div className="row header">
                    <div className="col-12 text-center">
                        <Navbar />
                    </div>
                    <div className="col-12 text-center">
                        <h1>Stay Up To Date with Digital Transformation</h1>
                    </div>
                </div>
                <div className="container">
                    <PostList />
                </div>
                
                <Footer />
            </div>
        );
    }
}

export default connect(null, { })(BlogList);