import { NEW_LEAD_PHONE, GET_LEADS } from '../actions/types';

const initialState = {
    leads: [],
    lead: {}
};

export default function (state = initialState, action) {
    switch(action.type) {
        case GET_LEADS: 
            return {
                ...state,
                leads: action.payload
            }
        case NEW_LEAD_PHONE: 
            return {
                ...state,
                lead: action.payload
            }
        default:
            return state;
    }
}